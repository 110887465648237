import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { CreateVendor } from "../../../store/actionCreators/users";

export default function Settings() {
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");
  const [fullPhone, setFullPhone] = useState("");
  const [email, setEmail] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setFullPhone(countryCode + phone);
  }, [countryCode, phone]);

  const AddVendor = (e) => {
    e.preventDefault();
    let phone = fullPhone;

    let data = {
      firstName,
      lastName,
      phone,
      email,
    };
 
    dispatch(CreateVendor(data));
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Add Vendor
                </h6>
                <button
                  className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"                  
                  onClick={() => navigate(-1)}
                  >
                  Cancel
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={AddVendor}>
                <h6 className="text-slate-400 text-sm mt-3  font-bold uppercase"></h6>
                <div className="flex flex-wrap mb-6">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        First name
                      </label>
                      <input
                        placeholder="first name"
                        required
                        maxLength="50"
                        type="text"
                        onChange={(e) => setFirstName(event.target.value)}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Last name
                      </label>
                      <input
                        placeholder="last name"
                        required
                        maxLength="50"
                        onChange={(e) => setLastName(event.target.value)}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Email
                      </label>
                      <input
                        placeholder="email@email.com"
                        required
                        type="email"
                        onChange={(e) => setEmail(event.target.value)}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Phone
                      </label>
                      <div className="w-full flex">
                        <input
                          disabled
                          defaultValue={countryCode}
                          placeholder="Country code, eg: +1"
                          pattern="[+][0-9]{1,3}"
                          onChange={(e) => setCountryCode(event.target.value)}
                          type="text"
                          maxLength="4"
                          style={{ width: "33%" }}
                          className="border-0 mr-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        />
                        <input
                          required
                          placeholder="1234567899"
                          pattern="[0-9]{10}"
                          onChange={(e) => setPhone(event.target.value)}
                          type="text"
                          maxLength="10"
                          style={{ width: "61%" }}
                          className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150 ml-auto"
                        />

                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs mt-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit">
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
