export * as actionCreators from "./actionCreators/users.js";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_PAGINATION = "SHOW_PAGINATION";

export const FETCH_SALES_ADMINS_REQUEST = "FETCH_SALES_ADMINS_REQUEST";
export const FETCH_SALES_ADMINS_SUCCESS = "FETCH_SALES_ADMINS_SUCCESS";
export const FETCH_SALES_ADMINS_FAILURE = "FETCH_SALES_ADMINS_FAILURE";

export const FETCH_SALES_ADMIN_REQUEST = "FETCH_SALES_ADMIN_REQUEST";
export const FETCH_SALES_ADMIN_SUCCESS = "FETCH_SALES_ADMIN_SUCCESS";
export const FETCH_SALES_ADMIN_FAILURE = "FETCH_SALES_ADMIN_FAILURE";

export const FETCH_VENDOR_REQUEST = "FETCH_VENDOR_REQUEST";
export const FETCH_VENDOR_SUCCESS = "FETCH_VENDOR_SUCCESS";
export const FETCH_VENDOR_FAILURE = "FETCH_VENDOR_FAILURE";

export const FETCH_VENDORS_REQUEST = "FETCH_VENDORS_REQUEST";
export const FETCH_VENDORS_SUCCESS = "FETCH_VENDORS_SUCCESS";
export const FETCH_VENDORS_FAILURE = "FETCH_VENDORS_FAILURE";

export const FETCH_VENDORS_BY_SALES_ADMIN_REQUEST =
  "FETCH_VENDORS_BY_SALES_ADMIN_REQUEST";
export const FETCH_VENDORS_BY_SALES_ADMIN_SUCCESS =
  "FETCH_VENDORS_BY_SALES_ADMIN_SUCCESS";
export const FETCH_VENDORS_BY_SALES_ADMIN_FAILURE =
  "FETCH_VENDORS_BY_SALES_ADMIN_FAILURE";

export const FETCH_CUSTOMERS_REQUEST = "FETCH_CUSTOMERS_REQUEST";
export const FETCH_CUSTOMERS_SUCCESS = "FETCH_CUSTOMERS_SUCCESS";
export const FETCH_CUSTOMERS_FAILURE = "FETCH_CUSTOMERS_FAILURE";

export const FETCH_CUSTOMERS_HISTORY_REQUEST =
  "FETCH_CUSTOMERS_HISTORY_REQUEST";
export const FETCH_CUSTOMERS_HISTORY_SUCCESS =
  "FETCH_CUSTOMERS_HISTORY_SUCCESS";
export const FETCH_CUSTOMERS_HISTORY_FAILURE =
  "FETCH_CUSTOMERS_HISTORY_FAILURE";

export const FETCH_USER_RISK_LEVEL_HISTORY_REQUEST =
  "FETCH_USER_RISK_LEVEL_HISTORY_REQUEST";
export const FETCH_USER_RISK_LEVEL_HISTORY_SUCCESS =
  "FETCH_USER_RISK_LEVEL_HISTORY_SUCCESS";
export const FETCH_USER_RISK_LEVEL_HISTORY_FAILURE =
  "FETCH_USER_RISK_LEVEL_HISTORY_FAILURE";

export const PATCH_USER_RISK_LEVEL_REQUEST = "PATCH_USER_RISK_LEVEL_REQUEST";
export const PATCH_USER_RISK_LEVEL_SUCCESS = "PATCH_USER_RISK_LEVEL_SUCCESS";
export const PATCH_USER_RISK_LEVEL_FAILURE = "PATCH_USER_RISK_LEVEL_FAILURE";

export const PUT_UNBLOCK_USER_REQUEST = "PUT_UNBLOCK_USER_REQUEST";
export const PUT_UNBLOCK_USER_SUCCESS = "PUT_UNBLOCK_USER_SUCCESS";
export const PUT_UNBLOCK_USER_FAILURE = "PUT_UNBLOCK_USER_FAILURE";

export const PUT_BLOCK_USER_REQUEST = "PUT_BLOCK_USER_REQUEST";
export const PUT_BLOCK_USER_SUCCESS = "PUT_BLOCK_USER_SUCCESS";
export const PUT_BLOCK_USER_FAILURE = "PUT_BLOCK_USER_FAILURE";

export const DELETE_SALES_ADMINS_REQUEST = "DELETE_SALES_ADMINS_REQUEST";
export const DELETE_SALES_ADMINS_SUCCESS = "DELETE_SALES_ADMINS_SUCCESS";
export const DELETE_SALES_ADMINS_FAILURE = "DELETE_SALES_ADMINS_FAILURE";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_FEEDBACK_REQUEST = "FETCH_FEEDBACK_REQUEST";
export const FETCH_FEEDBACK_SUCCESS = "FETCH_FEEDBACK_SUCCESS";
export const FETCH_FEEDBACK_FAILURE = "FETCH_FEEDBACK_FAILURE";

export const FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const POST_DOCUMENT_REQUEST = "POST_DOCUMENT_REQUEST";
export const POST_DOCUMENT_SUCCESS = "POST_DOCUMENT_SUCCESS";
export const POST_DOCUMENT_FAILURE = "POST_DOCUMENT_FAILURE";

export const FETCH_REVENUE_ANALYTICS_REQUEST =
  "FETCH_REVENUE_ANALYTICS_REQUEST";
export const FETCH_REVENUE_ANALYTICS_SUCCESS =
  "FETCH_REVENUE_ANALYTICS_SUCCESS";
export const FETCH_REVENUE_ANALYTICS_FAILURE =
  "FETCH_REVENUE_ANALYTICS_FAILURE";

export const FETCH_VIEWS_ANALYTICS_REQUEST = "FETCH_VIEWS_ANALYTICS_REQUEST";
export const FETCH_VIEWS_ANALYTICS_SUCCESS = "FETCH_VIEWS_ANALYTICS_SUCCESS";
export const FETCH_VIEWS_ANALYTICS_FAILURE = "FETCH_VIEWS_ANALYTICS_FAILURE";

export const FETCH_CANCELLATION_ANALYTICS_REQUEST =
  "FETCH_CANCELLATION_ANALYTICS_REQUEST";
export const FETCH_CANCELLATION_ANALYTICS_SUCCESS =
  "FETCH_CANCELLATION_ANALYTICS_SUCCESS";
export const FETCH_CANCELLATION_ANALYTICS_FAILURE =
  "FETCH_CANCELLATION_ANALYTICS_FAILURE";

export const FETCH_QUARTERLY_REPORTS_REQUEST =
  "FETCH_QUARTERLY_REPORTS_REQUEST";
export const FETCH_QUARTERLY_REPORTS_SUCCESS =
  "FETCH_QUARTERLY_REPORTS_SUCCESS";
export const FETCH_QUARTERLY_REPORTS_FAILURE =
  "FETCH_QUARTERLY_REPORTS_FAILURE";

export const FETCH_QUARTERLY_REPORT_FILE_REQUEST =
  "FETCH_QUARTERLY_REPORT_FILE_REQUEST";
export const FETCH_QUARTERLY_REPORT_FILE_SUCCESS =
  "FETCH_QUARTERLY_REPORT_FILE_SUCCESS";
export const FETCH_QUARTERLY_REPORT_FILE_FAILURE =
  "FETCH_QUARTERLY_REPORT_FILE_FAILURE";

export const POST_VENDOR_REQUEST = "POST_VENDOR_REQUEST";
export const POST_VENDOR_SUCCESS = "POST_VENDOR_SUCCESS";
export const POST_VENDOR_FAILURE = "POST_VENDOR_FAILURE";

export const VALIDATE = "VALIDATE";

export const SET_USER = "SET_USER";

export const LOGOUT = "LOGOUT";

export const FETCH_PAYMENT_METHODS_REQUEST = "FETCH_PAYMENT_METHODS_REQUEST";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILURE = "FETCH_PAYMENT_METHODS_FAILURE";

export const PAYMENT_METHOD_ADDED = "PAYMENT_METHOD_ADDED";

export const FETCH_USER_INFO_REQUEST = "FETCH_USER_INFO_REQUEST";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

export const FETCH_FEEDBACK_BY_ID_REQUEST = "FETCH_FEEDBACK_BY_ID_REQUEST";
export const FETCH_FEEDBACK_BY_ID_SUCCESS = "FETCH_FEEDBACK_BY_ID_SUCCESS";
export const FETCH_FEEDBACK_BY_ID_FAILURE = "FETCH_FEEDBACK_BY_ID_FAILURE";

export const FETCH_ADS_REQUEST = "FETCH_ADS_REQUEST";
export const FETCH_ADS_SUCCESS = "FETCH_ADS_SUCCESS";
export const FETCH_ADS_FAILURE = "FETCH_ADS_FAILURE";

export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_CATEGORY_REQUEST = "FETCH_CATEGORY_REQUEST";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILURE = "FETCH_CATEGORY_FAILURE";

export const STRIPE_ACCOUNT_ADDED = "STRIPE_ACCOUNT_ADDED";

export const FETCH_ADMIN_REVENUE_REQUEST = "FETCH_ADMIN_REVENUE_REQUEST";
export const FETCH_ADMIN_REVENUE_SUCCESS = "FETCH_ADMIN_REVENUE_SUCCESS";
export const FETCH_ADMIN_REVENUE_FAILURE = "FETCH_ADMIN_REVENUE_FAILURE";

export const FETCH_TOTAL_USERS_REQUEST = "FETCH_TOTAL_USERS_REQUEST";
export const FETCH_TOTAL_USERS_SUCCESS = "FETCH_TOTAL_USERS_SUCCESS";
export const FETCH_TOTAL_USERS_FAILURE = "FETCH_TOTAL_USERS_FAILURE";

export const FETCH_TOTAL_VENDORS_REQUEST = "FETCH_TOTAL_VENDORS_REQUEST";
export const FETCH_TOTAL_VENDORS_SUCCESS = "FETCH_TOTAL_VENDORS_SUCCESS";
export const FETCH_TOTAL_VENDORS_FAILURE = "FETCH_TOTAL_VENDORS_FAILURE";

export const FETCH_VENDOR_REVENUE_REQUEST = "FETCH_VENDOR_REVENUE_REQUEST";
export const FETCH_VENDOR_REVENUE_SUCCESS = "FETCH_VENDOR_REVENUE_SUCCESS";
export const FETCH_VENDOR_REVENUE_FAILURE = "FETCH_VENDOR_REVENUE_FAILURE";

export const FETCH_SERVICE_REQUEST = "FETCH_SERVICE_REQUEST";
export const FETCH_SERVICE_SUCCESS = "FETCH_SERVICE_SUCCESS";
export const FETCH_SERVICE_FAILURE = "FETCH_SERVICE_FAILURE";

export const FETCH_SEARCH_VENDORS_REQUEST = "FETCH_SEARCH_VENDORS_REQUEST";
export const FETCH_SEARCH_VENDORS_SUCCESS = "FETCH_SEARCH_VENDORS_SUCCESS";
export const FETCH_SEARCH_VENDORS_FAILURE = "FETCH_SEARCH_VENDORS_FAILURE";

export const FETCH_ALL_PRODUCTS_REQUEST = "FETCH_ALL_PRODUCTS_REQUEST";
export const FETCH_ALL_PRODUCTS_SUCCESS = "FETCH_ALL_PRODUCTS_SUCCESS";
export const FETCH_ALL_PRODUCTS_FAILURE = "FETCH_ALL_PRODUCTS_FAILURE";

export const FETCH_PRODUCT_REQUEST = "FETCH_PRODUCT_REQUEST";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_FAILURE";
