import React from "react";
import { Outlet } from "react-router-dom";

// components

import Navbar from "../components/Navbars/AuthNavbar.js";
import FooterSmall from "../components/Footers/FooterSmall.js";

// views

import Login from "../views/auth/Login.js";
import Register from "../views/auth/Register.js";

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            style={{
              background: "rgb(2,0,36)",
              background:
                "linear-gradient(180deg, rgba(0,228,191,1) 0%, rgba(53,180,206,1) 19%, rgba(134,60,250,1) 79%)",
            }}
            className="absolute top-0 w-full h-full bg-slate-800 bg-no-repeat bg-full"></div>
          <Outlet />
          {/* <FooterSmall absolute /> */}
        </section>
      </main>
    </>
  );
}
