import { createStore, applyMiddleware } from "redux";
import combinedReducer from "./reducers";
import thunk from "redux-thunk";
import promise from "redux-promise";
import logger from "redux-logger";

import history from "../history";

const middlewares = [thunk];
if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const store = createStore(combinedReducer, applyMiddleware(...middlewares));

export default store;
