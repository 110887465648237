import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetDocument,
  UpdateDocument,
} from ".././../store/actionCreators/settings";

// components

import TinyMCE from "../../components/TinyMCE";

export default function Settings() {
  const [text, setText] = useState("");
  const documentText = useSelector(
    (state) => state.settings.documentText.content
  );
  const role =
    sessionStorage.getItem("user") &&
    JSON.parse(sessionStorage.getItem("user")).roles[0];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetDocument("aboutapp"));
  }, []);

  useEffect(() => {
    setText(documentText);
  }, [documentText]);

  const SaveDocument = () => {
    let data = {
      content: text,
    };
    dispatch(UpdateDocument("aboutapp", data));
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">About US</h6>
                {role === "admin" && (
                  <button
                    onClick={SaveDocument}
                    className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button">
                    Save
                  </button>
                )}
              </div>
            </div>
            <TinyMCE text={text} setText={setText} title="" />
          </div>
        </div>
      </div>
    </>
  );
}
