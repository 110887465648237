import * as actionTypes from "../action-types";
// import Axios from "axios";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";
import { parseError } from "../../utils/common";

export const GetSalesAdmins =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_SALES_ADMINS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/sales-admins?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_SALES_ADMINS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_SALES_ADMINS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const SearchSalesAdmins =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_SALES_ADMINS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/search-sales-admin/${query}?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_SALES_ADMINS_SUCCESS,
        payload: res,
      });

      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_SALES_ADMINS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetVendors =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_VENDORS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/vendors?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_VENDORS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_VENDORS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const SearchVendors =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    // dispatch({ type: actionTypes.FETCH_SEARCH_VENDORS_REQUEST });
    dispatch({ type: actionTypes.FETCH_VENDORS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/search-vendors/${query}?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        // type: actionTypes.FETCH_SEARCH_VENDORS_SUCCESS,
        type: actionTypes.FETCH_VENDORS_SUCCESS,
        payload: res,
      });

      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      // dispatch({ type: actionTypes.FETCH_SEARCH_VENDORS_FAILURE, error });
      dispatch({ type: actionTypes.FETCH_VENDORS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetSalesPersonVendors =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_VENDORS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/loggedinAdmin/vendors?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_VENDORS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_VENDORS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetCustomers =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CUSTOMERS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/customers?Page=${page}&PerPage=${limit}`
      );

      console.log(res);

      dispatch({
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetSalesadminCustomers =
  (page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CUSTOMERS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/salesadmin/customers?Page=${page}&PerPage=${limit}`
      );

      // console.log(res);

      dispatch({
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const SearchCustomers =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CUSTOMERS_REQUEST });
    try {
      const res = await Axios.get(
        `/v1/Admin/search-customers/${query}?Page=${page}&PerPage=${limit}`
      );
      dispatch({
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const SearchSalesAdminCustomers =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CUSTOMERS_REQUEST });
    try {
      const res = await Axios.get(
        `/v1/Admin/salesadmin/search-customers/${query}?Page=${page}&PerPage=${limit}`
      );
      dispatch({
        type: actionTypes.FETCH_CUSTOMERS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CUSTOMERS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetVendor = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_VENDOR_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    // const res = await Axios.get(`/v1/admin/vendors/${id}`);
    const res = await Axios.get(`/v1/Vendors/${id}/admin`);
    dispatch({
      type: actionTypes.FETCH_VENDOR_SUCCESS,
      payload: res.data,
      // payload: id,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    parseError(error);
    dispatch({
      type: actionTypes.FETCH_VENDOR_FAILURE,
      error,
    });
  }
};

export const GetVendorsBySalesAdmin =
  (id, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/sales-admins/${id}/createdVendors?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({
        type: actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_FAILURE,
        error,
      });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const UnblockUser = (id) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/${id}/unblock`);
        resolve(res);
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const BlockUser = (id) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/${id}/block`);
        resolve(res);
        // console.log("res", res);
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeleteSalesAdmin = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.DELETE_SALES_ADMINS_REQUEST });

  try {
    const res = await Axios.put(`/v1/Admin/sales-admin/${id}/softDelete`);

    https: dispatch({
      type: actionTypes.DELETE_SALES_ADMINS_SUCCESS,
    });

    Swal.fire("Sales person deleted").then(function () {
      window.location.reload();
    });
  } catch (error) {
    dispatch({ type: actionTypes.DELETE_SALES_ADMINS_FAILURE, error });
    parseError(error);
    // Swal.fire("Error, " + error.response.data.message);
  }
};

export const CreateVendor = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_VENDOR_REQUEST });

  try {
    const res = await Axios.post(`/v1/admin/vendor`, data);
    dispatch({
      type: actionTypes.POST_VENDOR_SUCCESS,
      payload: res.data,
    });
    Swal.fire("", "Vendor added", "success").then(function () {
      window.location.href = "/admin/vendors";
    });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: actionTypes.POST_VENDOR_FAILURE, error });
    Swal.fire("", error.response.data.message, "info");
  }
};

export const CreateSalesAdmin = (data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_VENDOR_REQUEST });

  try {
    const res = await Axios.post(`/v1/admin/sales_admin`, data);
    dispatch({
      type: actionTypes.POST_VENDOR_SUCCESS,
      payload: res.data,
    });
    Swal.fire("", "Sales admin added", "success").then(function () {
      window.location.href = "/admin/sales-persons";
    });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: actionTypes.POST_VENDOR_FAILURE, error });
    Swal.fire("", error.response.data.message, "info");
  }
};

export const ApproveService = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Services/${id}/approve`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeclineService = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Services/${id}/decline`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const ApproveVendorProfile = (id, section, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Vendors/${id}/approve/${section}`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeclineVendorProfile = (id, section, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Vendors/${id}/decline/${section}`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeactivateVendor = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/vendors/deactivate/${id}`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const ActivateVendor = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/vendors/reaeactivate/${id}`, data);
        resolve(res);
        console.log("res", res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const GetVendorsService = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_SERVICE_REQUEST });
  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/v1/Services/${id}/admin`);
    dispatch({
      type: actionTypes.FETCH_SERVICE_SUCCESS,
      payload: res.data,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    dispatch({
      type: actionTypes.FETCH_SERVICE_FAILURE,
      error,
    });
  }
};
