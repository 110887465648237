import React from "react";

// components

import CardStats from "../../components/Cards/CardStats.js";

export default function Header() {
  const [showAlert, setShowAlert] = React.useState(false);

  return (
    <>
      {/* Header */}
      <div className="relative md:pt-32 pb-16 pt-12">
        {showAlert ? (
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
                    <div className="text-xl inline-block mr-4 align-middle">
                      <i className="fas fa-bell" />
                    </div>
                    <div className="inline-block align-middle mr-8">
                      This is a how a notification is gonna look
                    </div>
                    <button
                      className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-4 outline-none focus:outline-none"
                      onClick={() => setShowAlert(false)}>
                      <div>×</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
