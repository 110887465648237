import api from "./api";
import TokenService from "./token.service";

const login = (data) => {
  return api.post("/v1/admin/login", data).then((response) => {
    console.log(response);
    if (response.accessToken) {
      TokenService.setUser(response);
    }
    return response;
  });
};

const firstTimeLogin = (data) => {
  return api.post("/v1/Admin/first-time/Login", data);
};

const postPassword = (data) => {
  return api.post("/v1/admin/setPassword", data);
};

const forgotPassword = (data) => {
  return api.post("/v1/account/forgotPassword", data);
};

const resetPassword = (data) => {
  return api.post("/v1/admin/resetPassword", data);
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(sessionStorage.getItem("user"));
};

const AuthService = {
  login,
  firstTimeLogin,
  logout,
  getCurrentUser,
  postPassword,
  forgotPassword,
  resetPassword,
};

export default AuthService;
