import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function ProductDetails({
  // product,
  showModal,
  setShowModal,
  approve,
  decline,
  // changes,
}) {
  const product = useSelector((state) => state.products.selectedProduct);

  useEffect(() => {}, [product, showModal]);

  const Badge = () => {
    let badge = (
      <span className="text-xs text-emerald-500 bg-emerald-100 border border-slate-300 rounded-md  px-2 font-semibold ml-2">
        Changed
      </span>
    );

    return badge;
  };

  console.log("product?.productCategoryModel", product?.productCategoryModel);
  return (
    <>
      {showModal ? (
        <div className="fixed top-10 mx-auto max-w-5xl profile-modal shadow-2xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
              <h3 className="text-xl font-medium">More info</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="grid grid-cols-12 gap-12 rounded-xl  bg-white p-8">
              <div className="flex justify-between items-center mb-8">
                <h1 className="text-[#333333] text-xl xl:text-2xl font-bold">
                  {product?.vendorName}
                </h1>
              </div>
              <div className="col-span-12 lg:col-span-6">
                {product?.changes?.productImage?.url ? (
                  <>
                    <img
                      className="rounded-xl h-[150px] w-full object-cover"
                      src={product?.changes?.productImage?.url}
                      style={{
                        height: "350px",
                        borderRadius: 20,
                        objectFit: "contain",
                      }}
                    />
                    <Badge />
                  </>
                ) : (
                  <img
                    className="rounded-xl h-[150px] w-full object-cover"
                    src={product?.productViewModel?.productImage?.url}
                    style={{
                      height: "350px",
                      borderRadius: 20,
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
              <div className="col-span-12 lg:col-span-6 p-4">
                <div className="flex justify-between items-center mb-8">
                  <h1 className="text-[#333333] text-2xl xl:text-4xl font-bold capitalise">
                    {product?.changes?.name ? (
                      <span>
                        {product?.changes?.name}
                        <Badge />
                      </span>
                    ) : (
                      product?.productViewModel?.name
                    )}
                  </h1>
                </div>

                <div className="flex items-center mb-8">
                  {product?.productCategoryModel?.map((item) => {
                    return (
                      <span
                        key={item.id}
                        className={`rounded text-xs uppercase font-semibold h-fit px-2 py-1  whitespace-nowrap bg-sky-500 text-white mr-4`}
                      >
                        {item.categoryName}
                      </span>
                    );
                  })}
                </div>

                <div className="flex items-start justify-between text-[#333333] text-lg mb-8">
                  <div className="mr-10">
                    <p>Price</p>
                    {product?.changes?.price ? (
                      <span className="font-bold">
                        {product?.changes?.price}
                        <Badge />
                      </span>
                    ) : (
                      <span className="font-bold">
                        ${product?.productViewModel?.price}
                      </span>
                    )}
                  </div>
                  <div className="mr-10">
                    <p>Quantity</p>
                    <span className="font-bold">
                      {product?.productViewModel?.quantity}
                    </span>
                  </div>
                  <div className="mr-10">
                    <p>Weight</p>
                    <span className="font-bold">{`${product?.productViewModel?.weight} ${product?.productViewModel?.unit}`}</span>
                  </div>
                </div>
                <div className="">
                  <p className="text-[#333333] text-lg mb-2">Description</p>
                  {product?.changes?.description ? (
                    <div className="flex">
                      <p>{product?.changes?.description}</p>
                      <Badge />
                    </div>
                  ) : (
                    <p>{product?.productViewModel?.description}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end p-8">
              {(product?.productViewModel?.approvalStatus &&
                product?.productViewModel?.approvalStatus ===
                  "WAITING_FOR_APPROVAL") ||
              product?.productViewModel?.approvalStatus === "DECLINED" ||
              product?.productViewModel?.hasChanges ? (
                <>
                  <button
                    className=" text-white bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 mr-4"
                    onClick={() => decline(product?.id)}
                  >
                    Decline
                  </button>
                  <button
                    className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 ml-auto "
                    onClick={() => approve(product?.id)}
                  >
                    Approve
                  </button>
                </>
              ) : (
                ""
              )}
              <button
                className="bg-slate-200  active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 mr-8 ml-2"
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
