import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
// layouts

import AdminLayout from "./layouts/Admin.js";

import AuthLayout from "./layouts/Auth.js";
import Login from "./views/auth/Login.js";
import NewPassword from "./views/auth/NewPassword.js";
import ForgotPassword from "./views/auth/ForgotPassword.js";

// views without layouts

import Landing from "./views/Landing.js";
import Profile from "./views/Profile.js";
import Index from "./views/Index.js";

// views

import Dashboard from "./views/Dashboard";
import Vendors from "./views/Vendors";
import Customers from "./views/Customers";
import PostAds from "./views/PostAds";

import SalesPersons from "./views/SalesPersons";

import Settings from "./views/admin/Settings.js";
import TermsOfService from "./views/TermsOfService";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AboutUs from "./views/AboutUs";
import ChangePassword from "./views/ChangePassword/";
import PaymentDetails from "./views/PaymentDetails";
import AddPaymentDetails from "./views/PaymentDetails/AddPaymentDetails";

import Tables from "./views/admin/Tables.js";
import Feedback from "./views/Feedback";
import AddVendor from "./views/Vendors/AddVendor/";
import VendorDetails from "./views/Vendors/VendorDetails/";
import AddSalesPerson from "./views/SalesPersons/AddSalesPerson/";
import EditSalesPerson from "./views/SalesPersons/EditSalesPerson/";
import Categories from "./views/Categories";
import Products from "./views/Products";

import history from "./history";

import { Provider } from "react-redux";
import store from "./store/store";
// import PrivateRoute from "./PrivateRoute";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b3c870f557354dc7a89ef76fc2b1c766@o423451.ingest.sentry.io/4504719010037760",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.MODE,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/auth/login" />} />
        {/* <PrivateRoute path="/admin" component={Admin} /> */}
        {/* <Route path="/admin" element={<Admin />} /> */}
        {/* <Route path="/auth" element={<Auth />} /> */}
        <Route element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />}>
            <Route path="temp" element={<Login />} />
          </Route>

          {/* <Route path="/auth/register" element={<Register />} /> */}
          <Route path="/auth/new-password" element={<NewPassword />} />
          <Route path="/auth/reset-password" element={<NewPassword />} />

          <Route path="/auth/forgot-password" element={<ForgotPassword />} />

          <Route
            path="*"
            element={<Navigate from="/auth" to="/auth/login" />}
          />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/vendors" element={<Vendors />} />
          <Route path="/admin/vendors/:id" element={<VendorDetails />} />
          <Route path="/admin/sales-persons" element={<SalesPersons />} />
          <Route path="/admin/customers" element={<Customers />} />
          <Route path="/admin/post-ads" element={<PostAds />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/tables" element={<Tables />} />
          <Route path="/admin/about-us" element={<AboutUs />} />
          <Route path="/admin/change-password" element={<ChangePassword />} />
          <Route path="/admin/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/admin/terms-of-service" element={<TermsOfService />} />
          <Route path="/admin/feedback" element={<Feedback />} />
          <Route path="/admin/payment-details" element={<PaymentDetails />} />
          <Route path="/admin/categories" element={<Categories />} />
          {/* <Route
            path="/admin/payment-details/add"
            element={<AddPaymentDetails />}
          /> */}

          <Route path="/admin/vendors/add" element={<AddVendor />} />
          <Route path="/admin/sales-persons/add" element={<AddSalesPerson />} />
          <Route
            path="/admin/sales-persons/edit/:id"
            element={<EditSalesPerson />}
          />

          <Route
            path="*"
            element={<Navigate from="admin" to="/admin/dashboard" />}
          />
          <Route path="/admin/products" element={<Products />} />
        </Route>
        {/* add routes without layouts */}
        <Route path="/landing" element={<Landing />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>{" "}
  </Provider>
);
