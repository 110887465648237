import React, { useState, useEffect } from "react";
import { useLocation, Link, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toHours, toMinutes, toHoursAndMinutes } from "../../../utils/common";
import ImgHolder from "../../../assets/img/ImgHolder.png";
import {
  ApproveService,
  DeclineService,
  GetVendor,
  GetVendorsService,
} from "../../../store/actionCreators/users";
import { GetCategories } from "../../../store/actionCreators/categories";
import Swal from "sweetalert2";

export default function Service({ service }) {
  const [showModal, setShowModal] = useState(false);
  const [serviceById, setServiceById] = useState();
  const [changes, setChanges] = useState();
  const [newCategory, setNewCategory] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedservice = useSelector((state) => state.users.service);
  const categories = useSelector((state) => state.categories.categories.items);
  const vendor = useSelector((state) => state.users.selectedVendor);

  useEffect(() => {
    if (showModal) {
      dispatch(GetVendorsService(service.id));
      // dispatch(GetCategories(0, 100));
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedservice) {
      setServiceById(selectedservice.serviceById);
      setChanges(selectedservice.changes);
    }
  }, [selectedservice]);

  useEffect(() => {
    if (categories && selectedservice) {
      if (!selectedservice?.changes?.categoryId) {
        return;
      } else {
        let found = categories.find(
          (category) => category.id === selectedservice?.changes?.categoryId
        );
        setNewCategory(found);
      }
    }
  }, [categories, selectedservice]);

  function getStatusDetail(s) {
    let text = "Saved";
    let cls = "bg-gray text-white ";
    switch (s) {
      case "APPROVED":
        text = "Approved";
        cls = "bg-sky-500 text-white px-2 rounded";
        break;
      case "WAITING_FOR_APPROVAL":
        text = "Waiting for approval";
        cls = "bg-orange-200 text-orange-500 px-2 rounded";
        break;
      case "DECLINED":
        text = "Declined";
        cls = "bg-red-500 text-white px-2 rounded";
        break;
    }
    return { text: text, cls: cls };
  }

  function ServiceStatus({ s }) {
    let detail = getStatusDetail(s);
    return (
      <span
        className={`rounded-sm text-xs h-fit uppercase px-1 font-semibold whitespace-nowrap ${detail.cls}`}
      >
        {detail.text}
      </span>
    );
  }
  const Badge = () => {
    let badge = (
      <span className="text-xs text-emerald-500 bg-emerald-100 border border-slate-300 rounded-md  px-2 font-semibold ml-2">
        Edited
      </span>
    );

    return badge;
  };

  function ServiceDescription({ description }) {
    return (
      <p className="text-sm font-light text-justify leading-5">
        {description.substring(0, 160)}
      </p>
    );
  }

  const approve = (id) => {
    const data = {
      id: id,
    };

    dispatch(ApproveService(id, data))
      .then((res) => {
        Swal.fire(`Approved!`, "", "success");
        dispatch(GetVendor(vendor.userId));
        dispatch(GetVendorsService(service.id));
        setShowModal(false);
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const decline = (id) => {
    const data = {
      id: id,
    };

    dispatch(DeclineService(id, data))
      .then((res) => {
        Swal.fire(`Declined!`, "", "success");
        dispatch(GetVendor(vendor.userId));
        dispatch(GetVendorsService(service.id));
        setShowModal(false);
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };
  // console.log("categories", categories);
  return (
    <div className="rounded-lg p-4 ">
      {service && service?.serviceImage ? (
        <img
          src={service?.serviceImage.url}
          className="rounded-xl ease-out shadow-[0_9px_18px_-4px_rgba(0,0,0,0.6)] transition-all hover:shadow-[0_9px_18px_-7px_rgba(0,0,0,0.6)] hover:animate-slideTop object-cover w-full h-[180px] mx-auto mb-4"
          style={{ height: "200px", objectFit: "cover" }}
        />
      ) : (
        <img
          src={ImgHolder}
          className="rounded-xl ease-out shadow-[0_9px_18px_-4px_rgba(0,0,0,0.6)] transition-all hover:shadow-[0_9px_18px_-7px_rgba(0,0,0,0.6)] hover:animate-slideTop object-cover w-full h-[180px] mx-auto mb-4"
          style={{ maxWidth: "200px" }}
        />
      )}

      <div className="container px-2 mb-4" style={{ minHeight: 100 }}>
        <div className="row flex justify-between my-2 align-middle items-center">
          <span className="font-light text-xs text-brandingPrimary">
            {` ( ${toHours(service.estimatedDuration)} ${
              toHours(service.estimatedDuration) <= 1 ? "hour" : "hours"
            }`}
            {toMinutes(service.estimatedDuration) > 0
              ? `, ${toMinutes(service.estimatedDuration)} mins )`
              : " )"}
          </span>
          <ServiceStatus s={service?.approvalStatus} />
        </div>

        <div className="text-lg row font-bold mb-2">{service.name}</div>
        <div className="row font-semibold mb-2">{service?.categoryName}</div>
        <div className="font-semibold mb-2">${service.hourlyRate}/hr</div>
        <div style={{ overflowY: "auto" }}>
          <ServiceDescription description={service.description} />
        </div>
      </div>

      <button
        className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full my-4"
        onClick={() => setShowModal(true)}
      >
        View Service
      </button>

      {showModal ? (
        <>
          <div className="fixed top-10 mx-auto max-w-5xl service-modal shadow-2xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
                <h3 className="text-xl font-medium">More info</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="items-center p-4 border-collapse">
                  {serviceById && serviceById?.serviceImages ? (
                    <img
                      src={
                        changes?.imageId
                          ? changes?.imageId?.url
                          : serviceById?.serviceImages[0]?.url
                      }
                      className="rounded-lg ease-out shadow-[0_9px_18px_-4px_rgba(0,0,0,0.6)] transition-all hover:shadow-[0_9px_18px_-7px_rgba(0,0,0,0.6)] hover:animate-slideTop object-cover h-[250px] w-full mx-auto mb-4"
                      style={{ height: "250px", objectFit: "cover" }}
                    />
                  ) : (
                    <img
                      src={ImgHolder}
                      className="rounded-xl ease-out shadow-[0_9px_18px_-4px_rgba(0,0,0,0.6)] transition-all hover:shadow-[0_9px_18px_-7px_rgba(0,0,0,0.6)] hover:animate-slideTop object-cover h-[180px] mx-auto mb-4"
                      style={{ height: "150px" }}
                    />
                  )}
                  <div className="container px-2 my-4">
                    <div className="row flex justify-between my-2 align-middle items-center">
                      {changes?.estimatedDuration ? (
                        <span className="font-light text-xs text-brandingPrimary">
                          {` ( ${toHours(changes?.estimatedDuration)} ${
                            toHours(changes?.estimatedDuration) <= 1
                              ? "hour"
                              : "hours"
                          }`}
                          {toMinutes(changes?.estimatedDuration) > 0
                            ? `, ${toMinutes(
                                changes?.estimatedDuration
                              )} mins )`
                            : " )"}

                          <Badge />
                        </span>
                      ) : (
                        <span className="font-light text-xs text-brandingPrimary">
                          {` ( ${toHours(serviceById?.estimatedDuration)} ${
                            toHours(serviceById?.estimatedDuration) <= 1
                              ? "hour"
                              : "hours"
                          }`}
                          {toMinutes(serviceById?.estimatedDuration) > 0
                            ? `, ${toMinutes(
                                serviceById?.estimatedDuration
                              )} mins )`
                            : " )"}
                        </span>
                      )}
                      <ServiceStatus s={serviceById?.approvalStatus} />
                    </div>

                    <div className="text-lg row my-4 font-bold ">
                      {changes?.name ? (
                        <div>
                          <span>{changes?.name}</span> <Badge />
                        </div>
                      ) : (
                        <span>{serviceById?.name}</span>
                      )}
                    </div>

                    <div className="row my-4 text-sm">
                      {changes?.categoryId ? (
                        <div>
                          <span
                            className="bg-slate-200 px-2 rounded"
                            style={{ paddingBottom: 2 }}
                          >
                            {newCategory?.name}
                          </span>{" "}
                          <Badge />
                        </div>
                      ) : (
                        <span
                          className="bg-slate-200 px-2 rounded"
                          style={{ paddingBottom: 2 }}
                        >
                          {serviceById?.category.name}
                        </span>
                      )}
                    </div>

                    {changes?.hourlyRate ? (
                      <div className="font-semibold mb-2">
                        ${changes?.hourlyRate}/hr <Badge />
                      </div>
                    ) : (
                      <div className="font-semibold mb-2">
                        ${serviceById?.hourlyRate}/hr
                      </div>
                    )}
                    {changes?.description ? (
                      <div>
                        {changes?.description} <Badge />
                      </div>
                    ) : (
                      <div>{serviceById?.description}</div>
                    )}
                    {/* {changes?.requireConsultation ? (
                      <div>
                        {changes?.requireConsultation} <Badge />
                      </div>
                    ) : (
                      <div className="font-semibold mb-2">
                        Require Consultation
                      </div>
                    )} */}
                    {/* {serviceById?.requireConsultation && (
                      <div>
                        Consultation Fee{" "}
                        <span className="font-semibold mb-2">
                          ${serviceById?.consultationFee}
                        </span>
                      </div>
                    )} */}
                  </div>
                  {serviceById?.approvalStatus === "WAITING_FOR_APPROVAL" ? (
                    <div className="flex justify-end">
                      <button
                        className=" text-white bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 mr-4"
                        onClick={() => decline(serviceById?.id)}
                      >
                        Decline
                      </button>
                      <button
                        className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 ml-auto "
                        onClick={() => approve(serviceById?.id)}
                      >
                        Approve
                      </button>

                      <button
                        className="bg-slate-200  active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 my-4 mr-8 ml-2"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
