import React, { useState, useEffect } from "react";

import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { getLogin, firstTimeLogin } from "../../store/actionCreators/accounts";

export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [isTempLogin, setIsTempLogin] = useState(false);

  const [loginType, setLoginType] = useState("password");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.loading.show);

  useEffect(() => {
    setEmail(searchParams.get("email"));
    let parts = window.location.pathname.split("/");

    setCurrentUrl(parts[3]);
  }, []);

  const checkvalidEmail = (value) => {
    if (!value || value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null) {
      setEmailError(<small className="text-error">Email is required</small>);
    } else {
      setEmailError(null);
    }
  };

  const login = (e) => {
    e.preventDefault();

    let obj = {
      email: email,
      password: password,
    };
    if (currentUrl === "temp") {
      dispatch(firstTimeLogin(obj)).then(function () {
        window.location.href = `/auth/new-password/`;
        localStorage.setItem("emailForReset", email);
        localStorage.setItem("tempPasswordReset", password);
      });
    } else {
      dispatch(getLogin(obj)).then(function () {
        navigate("/admin/dashboard");
      });
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-auto ">
          <div className="w-full lg:w-6/12 px-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-32 shadow-lg rounded-lg bg-white  border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-indigo-600 text-3xl font-bold">
                    Welcome
                  </h6>
                  {isTempLogin && (
                    <h6 className="text-slate-500 text-xl mt-2">
                      Please login with your temporary password
                    </h6>
                  )}
                </div>

                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={login}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email or Username"
                      required
                      defaultValue={email}
                      maxLength={150}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      onBlur={(event) => {
                        checkvalidEmail(event.target.value);
                      }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      // type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      required
                      type={loginType}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={loading}
                      className="bg-indigo-600 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
                <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2">
                    <Link
                      to="/auth/forgot-password"
                      className="text-blueGray-200"
                    >
                      <small>Forgot password?</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
