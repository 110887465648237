import * as actionTypes from "../action-types";

let initialState = {
  data: {},
  loading: false,
  error: null,
};
const lossLimitNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_QUARTERLY_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_QUARTERLY_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.FETCH_QUARTERLY_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_QUARTERLY_REPORT_FILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_QUARTERLY_REPORT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FETCH_QUARTERLY_REPORT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default lossLimitNotificationsReducer;
