import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components

import DataTable from "./datatable";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetSalesAdmins,
  SearchSalesAdmins,
} from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = ["First Name", "Last Name", "Email", "Phone Number", "Action"];

  const state = useSelector((state) => state.users.salesAdmins);
  const user = useSelector((state) => state.account.userInfo);
  const navigate = useNavigate();

  let isAdmin =
    user?.roles?.includes("administrator") ||
    user?.roles?.includes("superadministrator");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin) {
      navigate("/admin/vendors");
      return;
    } else {
      if (searchValue) {
        const timeoutId = setTimeout(() => {
          dispatch(
            SearchSalesAdmins(encodeURIComponent(searchValue), page, limit)
          );
        }, 700);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetSalesAdmins(searchValue, page, limit));
      }
    }
  }, [page, limit, searchValue, isAdmin]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetSalesAdmins(searchValue, page, limit));
    }
  };

  const actionFunction = (id) => {
    window.location.replace(`/admin/sales-persons/add`);
  };

  console.log("searchValue", searchValue);

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <DataTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Sales Persons"
            thead={thead}
            data={state && state.items}
            actionFunction={actionFunction}
          />
          {/* {pagination && ( */}
          <Pagination
            page={page}
            onPage={state.currentPage}
            setPage={setPage}
            totalPages={state.totalPages}
            searchValue={searchValue}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
}
