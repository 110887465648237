import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// components

import ProductsTable from "../Vendors/VendorDetails/Products/datatable";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllProducts,
  GetSalesPersonProducts,
} from ".././../store/actionCreators/products";
import Swal from "sweetalert2";

export default function Products() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState();

  const thead = [
    "Product",
    "Price",
    "Vendor Name",
    "Status",
    "Approval Status",
    "Action",
  ];

  const products = useSelector((state) => state.products.products);
  const user = useSelector((state) => state.account.userInfo);

  const dispatch = useDispatch();

  // let isAdmin =
  //   user?.roles?.includes("administrator") ||
  //   user?.roles?.includes("superadministrator");

  // let isSalesRep = user?.roles?.includes("sales_officer");

  // useEffect(() => {
  //   if (user && isAdmin) {
  //     if (searchValue) {
  //       const timeoutId = setTimeout(() => {
  //         dispatch(
  //           GetAllProducts(encodeURIComponent(searchValue), page, limit)
  //         );
  //       }, 700);
  //       return () => {
  //         clearTimeout(timeoutId);
  //       };
  //     } else {
  //       dispatch(GetAllProducts(searchValue, page, limit));
  //     }
  //   } else if (user && isSalesRep) {
  //     if (searchValue) {
  //       const timeoutId = setTimeout(() => {
  //         dispatch(
  //           GetSalesPersonProducts(encodeURIComponent(searchValue), page, limit)
  //         );
  //       }, 700);
  //       return () => {
  //         clearTimeout(timeoutId);
  //       };
  //     } else {
  //       dispatch(GetSalesPersonProducts(searchValue, page, limit));
  //     }
  //   }
  // }, [page, limit, user, searchValue, isAdmin]);

  const { roles } = user || {};

  const isAdmin =
    roles?.includes("administrator") || roles?.includes("superadministrator");
  const isSalesRep = roles?.includes("sales_officer");

  useEffect(() => {
    let getProductFn;

    switch (true) {
      case isAdmin:
        getProductFn = GetAllProducts;
        break;
      case isSalesRep:
        getProductFn = GetSalesPersonProducts;
        break;
      default:
        return;
    }

    if (searchValue) {
      const timeoutId = setTimeout(() => {
        dispatch(getProductFn(encodeURIComponent(searchValue), page, limit));
      }, 700);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(getProductFn(searchValue, page, limit));
    }
  }, [page, limit, user, searchValue, isAdmin, isSalesRep]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetAllProducts(searchValue, page, limit));
    }
  };
  const resetSearch = () => {
    window.location.reload();
  };

  return (
    <>
      <AdminNavbar />
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ProductsTable
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            handleKeyDown={handleKeyDown}
            title="Products"
            thead={thead}
            data={products && products.items}
            isAdmin={isAdmin}
            isSalesRep={isSalesRep}
            resetSearch={resetSearch}
            page={page}
            limit={limit}
          />
          <Pagination
            page={page}
            onPage={products && products.currentPage}
            setPage={setPage}
            totalPages={products && products.totalPages}
            searchValue={searchValue}
          />
        </div>
      </div>
    </>
  );
}
