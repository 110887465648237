import * as actionTypes from "../action-types";
import AuthService from "../../services/auth.service";
import Swal from "sweetalert2";
import Axios from "../../services/api.js";
import { parseError } from "../../utils/common";

export const validate = () => {
  let token = sessionStorage.getItem("access_token");
  if (token) {
    return {
      type: actionTypes.VALIDATE,
      payload: true,
    };
  } else {
    return {
      type: actionTypes.VALIDATE,
      payload: false,
    };
  }
};
export const getLogin = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.login(data);
        dispatch({ type: actionTypes.VALIDATE, payload: true });
        dispatch({ type: actionTypes.SET_USER, payload: res });
        resolve();
      } catch (error) {
        console.log(error.response);

        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const firstTimeLogin = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.firstTimeLogin(data);
        resolve();
      } catch (error) {
        console.log(error.response);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const postPassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.postPassword(data);
        Swal.fire(
          "Password changed!",
          "Please login to continue!",
          "success"
        ).then(function () {
          window.location.replace("/auth/login");
        });
        resolve();
      } catch (error) {
        console.log("error.response", error.response.data);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const forgotPassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.forgotPassword(data);
        Swal.fire(
          "Email sent!",
          "Please check your inbox for the code to reset the password!",
          "success"
        ).then(function () {
          window.location.replace("/auth/reset-password");
        });
        resolve();
      } catch (error) {
        console.log(error.response);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const resetPassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await AuthService.resetPassword(data);
        Swal.fire(
          "Password changed!",
          "Please login to continue!",
          "success"
        ).then(function () {
          window.location.replace("/auth/login");
        });
        resolve();
      } catch (error) {
        // parseError(error);
        if (error?.response?.data === "Invalid code or email") {
          Swal.fire("", error?.response?.data, "error");
        }
        if (error.response?.data?.errors) {
          console.log("Errors", error.response.data.errors);
          let errors = error.response.data.errors;
          function errorMessage(content) {
            Swal.fire("", content, "error");
            console.log("content", content);
          }
          if (Object.keys(errors).length > 0) {
            let errorMsg = "";
            Object.keys(errors).forEach((e) => {
              errorMessage(`${errors[e]}`);
              errorMsg += `${errors[e]}`;
            });
            return errorMsg;
          } else {
            errorMessage(
              "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists."
            );
            return "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists.";
          }
        }
        let errMessage = error.response.data.message;
        if (error.response?.data?.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const changePassword = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post("/v1/account/changepassword", data);

        Swal.fire("", "Password changed!", "success").then(function () {
          window.location.reload();
        });
        resolve();
      } catch (error) {
        console.log(error);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const Logout = () => {
  sessionStorage.removeItem("access_token");
  localStorage.clear();
  sessionStorage.clear();
  return {
    type: actionTypes.VALIDATE,
    payload: false,
  };
};

export const GetLoggedInUserInfo = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_USER_INFO_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/v1/account/me`);

    dispatch({ type: actionTypes.FETCH_USER_INFO_SUCCESS, payload: res });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    console.log(error);
    parseError(error);
    dispatch({ type: actionTypes.FETCH_USER_INFO_FAILURE, error });
  }
};

export const EditVendorInfo = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put("/v1/Admin/loggedinAdmin/edit", data);

        resolve(res);
      } catch (error) {
        console.log(error);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};
