import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Header from "../components/Headers/Header.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

// views

import LoadingOverlay from "react-loading-overlay";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";

import {
  GetAllPaymentMethods,
  PaymentMethodAdded,
  StripeAccountAdded,
} from "../store/actionCreators/paymentMethods";

import { GetLoggedInUserInfo } from "../store/actionCreators/accounts";

export default function Admin() {
  const state = useSelector((state) => state);
  const user = useSelector((state) => state.account.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetLoggedInUserInfo());
  }, []);

  // useEffect(() => {
  //   if (user?.roles?.includes("sales_officer")) {
  //     dispatch(GetAllPaymentMethods())
  //       .then((res) => {
  //         if (res.data.length < 1) {
  //           dispatch(PaymentMethodAdded(false));
  //           navigate("/admin/payment-details/add");
  //         } else {
  //           dispatch(PaymentMethodAdded(true));
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error", error.response);
  //       });
  //   }
  // }, [user]);

  useEffect(() => {
    if (
      user?.roles?.includes("sales_officer") &&
      !user.stripeExpressAccountId
    ) {
      dispatch(StripeAccountAdded(false));
      navigate("/admin/payment-details");
    } else {
      dispatch(StripeAccountAdded(true));
    }
  }, [user]);

  // console.log("state user", user);

  return (
    <>
      <Sidebar />
      <div className="relative bg-slate-100 admin-layout h-screen">
        <LoadingOverlay
          // styles={""}
          active={state.loading.show}
          spinner
          text="Loading, Please wait"
        >
          <Header />
          <div className="px-4 md:px-10 mx-auto w-full -m-24 ">
            <Outlet />
            {/* <FooterAdmin /> */}
          </div>
        </LoadingOverlay>
      </div>
    </>
  );
}
