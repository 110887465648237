import * as actionTypes from "../action-types";

let initialState = {
  loading: false,
  error: null,
  categories: [],
  selectedCategory: {},
};
const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.FETCH_CATEGORIES_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_CATEGORY_SUCCESS:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case actionTypes.FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default categoriesReducer;
