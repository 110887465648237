import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { GetVendorsBySalesAdmin } from "../../../store/actionCreators/users";
import {
  DeleteSalesAdmin,
  BlockUser,
  UnblockUser,
} from "../../../store/actionCreators/users";
import moment from "moment";
import Swal from "sweetalert2";
import * as actionTypes from "../../../store/action-types";
import UsersTable from "./vendors.js";
import Pagination from "../../../components/Pagination";

export default function EditRiskLevel({ slide, setSlide, moreInfo }) {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [blocking, setBlocking] = useState("");

  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = [
    "Logo",
    "Business Name",
    "Email",
    "Approval Status",
    "Joined On",
    "Action",
  ];

  const state = useSelector((state) => state.users.vendorsBySalesAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetVendorsBySalesAdmin(moreInfo.id, page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetVendorsBySalesAdmin(moreInfo.id, page, limit));
    }
  };

  const handleBlock = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (moreInfo.isBlocked) {
          dispatch(UnblockUser(id));
        } else {
          dispatch(BlockUser(id));
        }
      }
    });
  };

  const backButton = () => {
    setSlide("1");
    dispatch({ type: actionTypes.SHOW_PAGINATION, payload: true });
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteSalesAdmin(id));
      }
    });
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full 2xl:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Sales Person Details
                </h6>
                <div>
                  <button
                    className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    onClick={backButton}
                  >
                    Go back
                  </button>
                  {/* <button
                    onClick={(e) => handleBlock(e, moreInfo.id)}
                    className={
                      moreInfo.isBlocked
                        ? "bg-red-400"
                        : "success-green" +
                          " text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    }
                    type="button">
                    {moreInfo.isBlocked ? "Unblock" : "Block"}
                  </button> */}
                  <button
                    onClick={() => deleteUser(moreInfo.id)}
                    className="bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <h6 className="text-slate-400 text-sm mt-3  font-bold uppercase"></h6>
              <div className="flex flex-wrap items-center text-sm md:text-base">
                <div className="relative flex flex-col min-w-0 break-words  w-full mb-6">
                  <div className="rounded-t mb-0 px-2 py-3 border-0">
                    <div className="flex flex-wrap items-center text-sm md:text-base">
                      <div className="items-center w-full bg-transparent border-collapse">
                        <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex">
                          <h3 className="font-medium mr-2">Firstname: </h3>
                          <span>{moreInfo.firstName}</span>
                        </div>
                        <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">Lastname </h3>
                          <span> {moreInfo.lastName}</span>
                        </div>
                        <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">Email:</h3>
                          <span>{moreInfo.email}</span>
                        </div>

                        <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">Phone number:</h3>
                          <span>{moreInfo.phoneNumber}</span>
                        </div>
                        {/* <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">
                            Transactions Completed:
                          </h3>
                          <span>{moreInfo.transactionCompleted}</span>
                        </div> */}

                        <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">Created At:</h3>
                          <span>
                            {moment(moreInfo.createdAt).format("LLL")}
                          </span>
                        </div>

                        {/* <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                          <h3 className="font-medium mr-2">Blocked At:</h3>
                          <span>
                            {moreInfo.blockedAt
                              ? moment(moreInfo.blockedDate).format("LLL")
                              : "Not Blocked"}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mb-12 px-4">
              <UsersTable
                setSearchValue={setSearchValue}
                handleKeyDown={handleKeyDown}
                title="Vendors"
                thead={thead}
                data={state && state.items}
              />
              <Pagination
                page={page}
                onPage={state.currentPage}
                setPage={setPage}
                totalPages={state.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
