import Swal from "sweetalert2";
import moment from "moment";


export function getPasswordValidationRules() {
  let map = {
    specialOrNumber: new RegExp(/[^a-zA-Z0-9]|[0-9]/),
    length: new RegExp(/.{8,}/),
  };

  return map;
}

export const PasswordRule = {
  SpecialOrNumber: Symbol("specialOrNumber"),
  Length: Symbol("length"),
};

export const PasswordStrengthLevel = {
  Weak: Symbol("Weak"),
  Good: Symbol("Good"),
  Strong: Symbol("Strong"),
};

export const checkPassword = (str) => {
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
};

export const getFormatedHour12 = (hour) => {
  let hour12 = "";

  if (hour) {
    // converting char into integer
    let h1 = Number(hour[0] - "0");
    let h2 = Number(hour[1] - "0");

    let hourNum = h1 * 10 + h2;

    // obtaining meridien;
    let meridien;
    if (hourNum < 12) {
      meridien = " AM";
    } else {
      meridien = " PM";
    }

    hourNum %= 12;

    // 00 and 12 case
    if (hourNum == 0) {
      hour12 = "12";
    } else {
      hour12 = hourNum;
    }

    if (hour.length >= 5) {
      for (var i = 2; i < 5; i++) {
        hour12 += hour[i];
      }
    }

    hour12 += meridien;
  }

  return hour12;
};

// convert minutes to hours and minutes
export function toHours(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  return hours;
}
export function toMinutes(totalMinutes) {
  const minutes = totalMinutes % 60;
  return minutes;
}

export function toHoursAndMinutes(totalMinutes) {
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}

export function parseError(err) {
  if (err?.response?.data === "Invalid temporal password") {
    Swal.fire("", err?.response?.data, "error");
  }
  if (err.response?.data?.errors) {
    console.log("Errors", err.response.data.errors);
    let errors = err.response.data.errors;
    function errorMessage(content) {
      Swal.fire("", content, "error");
      console.log("content", content);
    }
    if (Object.keys(errors).length > 0) {
      let errorMsg = "";
      Object.keys(errors).forEach((e) => {
        if (
          errors[e]?.[0] ===
          "The field Password must be a string with a minimum length of 8 and a maximum length of 50."
        ) {
          errorMessage(
            "The password must contain 8 characters minimum, and at least 1 Capital letter, 1 number, and 1 special character."
          );
          errorMsg +=
            "The password must contain 8 characters minimum, and at least 1 Capital letter, 1 number, and 1 special character.";
        } else {
          errorMessage(`${errors[e]}`);
          errorMsg += `${errors[e]}`;
        }
      });
      return errorMsg;
    } else {
      errorMessage(
        "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists."
      );
      return "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists.";
    }
  }
  let errMessage = err.response.data.message;
  if (err.response?.data?.message) {
    Swal.fire("", errMessage, "error");
  }
  if (err.response.data.message === "Customer card has expired.") {    
    Swal.fire({
      title: "",
      text: errMessage,
      icon: "error",
      confirmButtonText: "Okay",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    });
  }
  if (err.response.data.message === "You need to change your password before logging in.") {    
    Swal.fire({
      title: "",
      text: errMessage,
      icon: "error",
      confirmButtonText: "Change password",
    }).then((result) => {
      if (result.isConfirmed) {        
        window.location.href = `/auth/login/temp/`;
      } else {
        window.location.href = `/auth/login/temp/`;
      }
    });
  }
}

// export function parseError(err) {
//   if (err.response?.data?.errors) {
//     console.log("Errors", err.response.data.errors);
//     let errors = err.response.data.errors;
//     function errorMessage(content) {
//       Swal.fire("", content, "error");
//       console.log("content", content);
//     }
//     if (Object.keys(errors).length > 0) {
//       let errorMsg = "";
//       Object.keys(errors).forEach((e) => {
//         errorMessage(`${errors[e]}`);
//         errorMsg += `${errors[e]}`;
//       });
//       return errorMsg;
//     } else {
//       errorMessage(
//         "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists."
//       );
//       return "Sorry, an unexpected error occurred. Please try again and contact us if the issue still persists.";
//     }
//   }
//   let errMessage = err.response.data.message;
//   if (err.response?.data?.message) {
//     Swal.fire("", errMessage, "error");
//   }
//   if (err.response.data.message === "Customer card has expired.") {
//     // Swal.fire("", errMessage, "error")
//     Swal.fire({
//       title: "",
//       text: errMessage,
//       icon: "error",
//       confirmButtonText: "Okay",
//     }).then((result) => {
//       if (result.isConfirmed) {
//         window.location.reload();
//       } else {
//         window.location.reload();
//       }
//     });
//   }
// }

export function formatISODate(date) {
  if (!date) {
    return "";
  }

  return `${moment(date).format("YYYY-MM-DDTHH:mm:ss.000")}Z`;
}

export function formatISODateWithoutTime(date) {
  if (!date) {
    return "";
  }

  return `${moment(date).format("YYYY-MM-DD")}T00:00:00.000Z`;
}

export function formatDateOnlyShort(date) {
  if (!date) {
    return "";
  }
  return moment.utc(date).local().format("YYYY-MM-DD");
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
}
