import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";

// components

import CardLineChart from "../../components/Cards/CardLineChart.js";
import CardBarChart from "../../components/Cards/CardBarChart.js";
import CardPageVisits from "../../components/Cards/CardPageVisits.js";
import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.js";
import ViewsAnalytics from "./viewsAnalytics.js";
import RevenueAnalytics from "./revenueAnalytics.js";
import CancellationAnalytics from "./cancellationAnalytics.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

import {
  GetAdminRevenue,
  GetCustomersTotal,
  GetVendorsTotal,
} from "../../store/actionCreators/analytics";
import CardStats from "../../components/Cards/CardStats.js";
import CardDashboardAnalytics from "../../components/Cards/CardDashboardAnalytics.js";
import { formatDateOnlyShort } from "../../utils/common";

export default function Tables() {
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [adminRevenue, setAdminRevenue] = React.useState();
  const [customers, setCustomers] = React.useState();
  const [vendors, setVendors] = React.useState();

  const user = useSelector((state) => state.account.userInfo);
  const stripeAccountAdded = useSelector(
    (state) => state.paymentMethods.stripeAccountAdded
  );
  const analytics = useSelector((state) => state.analytics);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (user?.roles?.includes("sales_officer") && !stripeAccountAdded) {
      navigate("/admin/payment-details");
    } else if (user?.roles?.includes("sales_officer") && stripeAccountAdded)
      navigate("/admin/vendors");
  }, [user, stripeAccountAdded]);

  let isAdmin =
    user?.roles?.includes("administrator") ||
    user?.roles?.includes("superadministrator");

  let fromDate = formatDateOnlyShort(startDate);
  let toDate = formatDateOnlyShort(endDate);

  useEffect(() => {
    if (!isAdmin) {
      return;
    } else {
      if (startDate || endDate) {
        const timeoutId = setTimeout(() => {
          dispatch(GetAdminRevenue(fromDate, toDate));
          dispatch(GetCustomersTotal(fromDate, toDate));
          dispatch(GetVendorsTotal(fromDate, toDate));
        }, 800);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetAdminRevenue());
        dispatch(GetCustomersTotal());
        dispatch(GetVendorsTotal());
      }
    }
  }, [isAdmin, startDate, endDate]);

  useEffect(() => {
    if (analytics) {
      setAdminRevenue(analytics?.adminrevenue);
      setCustomers(analytics?.totalusers);
      setVendors(analytics?.totalvendors);
    }
  }, [analytics]);

  return (
    <>
      <AdminNavbar />

      {user?.roles?.includes("administrator") ||
      user?.roles?.includes("superadministrator") ? (
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <div className="flex flex-wrap mb-8 px-4">
              <h6 className="text-slate-700 text-2xl font-bold">Analytics</h6>
            </div>
            <div className="flex flex-wrap mb-8">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Start Date
                  </label>
                  <DatePicker
                    value={startDate}
                    className="w-full rounded-lg border-0"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    // minDate={new Date()}
                    maxDate={endDate ? endDate : new Date()}
                    isClearable
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-slate-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    End Date
                  </label>
                  <DatePicker
                    value={endDate}
                    className="w-full rounded-lg border-0"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate ? startDate : ""}
                    maxDate={new Date()}
                    isClearable
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4 py-8">
                <CardDashboardAnalytics
                  statSubtitle="Admin Revenue"
                  statTitle={`$ ${adminRevenue}`}
                  statIconName="fas fa-dollar-sign"
                  statIconColor="bg-red-400"
                  statDescripiron="Admins' Commissions"
                />
              </div>
              <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4 py-8">
                <CardDashboardAnalytics
                  statSubtitle="Customers"
                  statTitle={`${customers}`}
                  statIconName="fas fa-users"
                  statIconColor="bg-sky-400"
                  statDescripiron="Users"
                />
              </div>
              <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4 py-8">
                <CardDashboardAnalytics
                  statSubtitle="Vendors"
                  statTitle={`${vendors}`}
                  statIconName="fas fa-user-plus"
                  statIconColor="bg-emerald-400"
                  statDescripiron="Users"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="m-10">
        <div
          style={{
            opacity: 0.3,
            padding: "1px",
            marginBottom: "8px",
          }}
          className="mb-12 bg-sky-500 mx-auto"
        ></div>
      </div>
      {user?.roles?.includes("administrator") ||
      user?.roles?.includes("superadministrator") ? (
        <div
          className="flex flex-wrap mt-12"
          style={{ borderTop: "2px ", borderTopColor: "blue" }}
        >
          <div className="w-full mb-12 px-4">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                <RevenueAnalytics title="Revenue" />
              </div>
              <div className="w-full xl:w-6/12 px-4">
                <ViewsAnalytics title="Views" />
              </div>
            </div>
            <div className="flex flex-wrap mt-4">
              <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
                <CancellationAnalytics title="Cancellations" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
