import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";
import { parseError } from "../../utils/common";

export const GetRevenueAnalytics =
  (page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_REVENUE_ANALYTICS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/analytics/revenue?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_REVENUE_ANALYTICS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);

      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      if (error.response.data.errorMessage) {
        Swal.fire("Error", error.response.data.errorMessage);
      } else {
        Swal.fire(
          "Error",
          "Something went wrong on ourside, Please try again later"
        );
      }
      dispatch({ type: actionTypes.FETCH_REVENUE_ANALYTICS_FAILURE, error });
    }
  };

export const GetCancellationAnalytics =
  (page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CANCELLATION_ANALYTICS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/analytics/cancellations?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_CANCELLATION_ANALYTICS_SUCCESS,
        payload: res,
      });

      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      if (error.response.data.errorMessage) {
        Swal.fire("Error", error.response.data.errorMessage);
      } else {
        Swal.fire(
          "Error",
          "Something went wrong on ourside, Please try again later"
        );
      }
      dispatch({
        type: actionTypes.FETCH_CANCELLATION_ANALYTICS_FAILURE,
        error,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    }
  };

export const GetViewsAnalytics =
  (page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_VIEWS_ANALYTICS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/admin/analytics/views?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_VIEWS_ANALYTICS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);

      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      if (error.response.data.errorMessage) {
        Swal.fire("Error", error.response.data.errorMessage);
      } else {
        Swal.fire(
          "Error",
          "Something went wrong on ourside, Please try again later"
        );
      }
      dispatch({ type: actionTypes.FETCH_VIEWS_ANALYTICS_FAILURE, error });
    }
  };

export const GetAdminRevenue =
  (fromDate = "", toDate = "") => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_ADMIN_REVENUE_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/analytics/adminrevenue?FromDate=${fromDate}&ToDate=${toDate}`
      );

      dispatch({
        type: actionTypes.FETCH_ADMIN_REVENUE_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);
      parseError(error);
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });     
      dispatch({ type: actionTypes.FETCH_ADMIN_REVENUE_FAILURE, error });
    }
  };

export const GetCustomersTotal =
  (fromDate = "", toDate = "") => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_TOTAL_USERS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/analytics/totalusers?FromDate=${fromDate}&ToDate=${toDate}`
      );

      dispatch({
        type: actionTypes.FETCH_TOTAL_USERS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);
      parseError(error);
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });     
      dispatch({ type: actionTypes.FETCH_TOTAL_USERS_FAILURE, error });
    }
  };

export const GetVendorsTotal =
  (fromDate = "", toDate = "") => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_TOTAL_VENDORS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/analytics/totalvendors?FromDate=${fromDate}&ToDate=${toDate}`
      );

      dispatch({
        type: actionTypes.FETCH_TOTAL_VENDORS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);
      parseError(error);
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });     
      dispatch({ type: actionTypes.FETCH_TOTAL_VENDORS_FAILURE, error });
    }
  };

export const GetVendorRevenue =
  (fromDate = "", toDate = "", userId) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_VENDOR_REVENUE_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Admin/analytics/vendorrevenue?FromDate=${fromDate}&ToDate=${toDate}&UserId=${userId}`
      );

      dispatch({
        type: actionTypes.FETCH_VENDOR_REVENUE_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      console.error(error);
      parseError(error);
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });     
      dispatch({ type: actionTypes.FETCH_VENDOR_REVENUE_FAILURE, error });
    }
  };
