import React, { useEffect, useState } from "react";

// components
import AdsTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import { useSelector, useDispatch } from "react-redux";
import {
  GetPendingAds,
  ApproveAd,
  DeclineAd,
} from ".././../store/actionCreators/settings";

export default function Ads() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("vendor");
  const [feedbackType, setFeedbackType] = useState("vendor");
  const thead = ["Vendor", "Email", "Description", "Date submitted", "Detail"];

  const ads = useSelector((state) => state.settings.ads);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetPendingAds(page, limit));
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetPendingAds(page, limit));
    }
  };

  const onPageChange = (value) => {
    setActiveTab(value);
    setFeedbackType(value);
    setPage(0);
  };

  return (
    <>
      <AdminNavbar />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ">
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h6 className="text-slate-700 text-2xl font-bold">
                    Pending ad requests
                  </h6>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto justify-between">
              <AdsTable
                setSearchValue={setSearchValue}
                handleKeyDown={handleKeyDown}
                thead={thead}
                data={ads.items}
                page={page}
                onPage={ads.currentPage}
                setPage={setPage}
                totalPages={ads.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
