import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCategories,
  GetCategoryById,
  ClearSelectedCategory,
  EditCategory,
  CreateCategory,
  DeleteCategory,
} from ".././../store/actionCreators/categories";
import Swal from "sweetalert2";

export default function Modal({
  showModal,
  setShowModal,
  categoryId,
  setCategoryId,
  page,
  limit,
}) {
  const [category, setCategory] = useState();
  const [editing, setEditing] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.categories.selectedCategory);

  useEffect(() => {
    if (showModal && categoryId) {
      dispatch(GetCategoryById(categoryId));
      setEditing(true);
    } else {
      setEditing(false);
    }
  }, [showModal, categoryId]);

  useEffect(() => {
    if (state && editing) {
      setCategory(state);
    } else {
      setCategory();
    }
  }, [state, editing]);

  const closeModal = () => {
    setShowModal(false);
    dispatch(ClearSelectedCategory());
    setCategoryId("");
    setEditing(false);
    setCategory();
  };

  const handleSaveChanges = (e, id) => {
    e.preventDefault();
    if (editing) {
      editCategory();
    } else {
      createCategory();
    }
  };

  const editCategory = () => {
    const id = categoryId;
    const data = {
      name: category.name,
      description: category.description,
      isActive: true,
      parentCategoryId: "",
      isParent: true,
      sortOrder: category.sortOrder,
    };

    dispatch(EditCategory(id, data))
      .then(() => {
        Swal.fire(`Category edited`, "", "success");
        dispatch(GetCategories(page, limit));
        closeModal();
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const createCategory = () => {
    const data = {
      name: category.name,
      description: category.description,
      isActive: true,
      parentCategoryId: "string",
      isParent: true,
    };

    dispatch(CreateCategory(data))
      .then(() => {
        Swal.fire(`Category created`, "", "success");
        dispatch(GetCategories(page, limit));
        closeModal();
        setEditing(false);
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const id = category.id;

    Swal.fire({
      title: "Delete category?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteCategory(id))
          .then(() => {
            Swal.fire(`Deleted!`, "", "success");
            dispatch(GetCategories(page, limit));
            closeModal();
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };
  console.log("page, limit", page, limit);
  return (
    <>
      {showModal ? (
        <>
          <div
            className="overflow-y-auto overflow-x-auto top-0 right-0 left-0 z-20 w-full
            md:inset-0 h-modal md:h-full bg-gray-600/25 fixed z-50 ads-modal-container"
          >
            <div className="h-screen w-screen flex justify-center items-center">
              <div className="w-full p-12 lg:px-48 h-full max-h-full md:h-auto flex justify-center">
                <div className="fixed top-10 mx-auto max-w-3xl ads-modal h-[80vh] ">
                  {/*content*/}
                  <div className="border-0 rounded relative flex flex-col w-full  outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
                      <h3 className="text-xl font-medium">
                        {editing ? "Edit Category" : "Add Category"}{" "}
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={closeModal}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}

                    <>
                      <div className="flex flex-wrap bg-slate-100 py-6 2xl:w-8/12">
                        <div className="w-full mx-auto">
                          <div className="relative flex flex-col min-w-0 break-words  mb-6  border-0">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                              <form>
                                <div className="relative w-full mb-3 ">
                                  <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                                    Name
                                  </label>
                                  <input
                                    placeholder="Name"
                                    required
                                    maxLength="50"
                                    type="text"
                                    value={category?.name || ""}
                                    onChange={(e) => {
                                      setCategory({
                                        ...category,
                                        name: e.target.value,
                                      });
                                    }}
                                    className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                  />
                                </div>

                                <hr className="my-4 border-b-1 border-slate-300" />

                                <div className="relative w-full mb-3 ">
                                  <div className="relative w-full mb-3">
                                    <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                                      Description
                                    </label>
                                    <textarea
                                      type="text"
                                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                      placeholder="Type here"
                                      rows="4"
                                      value={category?.description || ""}
                                      onChange={(e) => {
                                        setCategory({
                                          ...category,
                                          description: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <hr className="my-4 border-b-1 border-slate-300" />

                                {editing ? (
                                  <>
                                    <div className="flex ">
                                      <label className="block uppercase text-slate-600 text-xs font-bold  mr-4">
                                        Number of active services
                                      </label>
                                      <label className="block uppercase text-slate-600 text-xs font-bold ">
                                        {category?.numberOfActiveServices}
                                      </label>
                                    </div>

                                    <hr className="my-4 border-b-1 border-slate-300" />

                                    <div className="flex ">
                                      <label className="block uppercase text-slate-600 text-xs font-bold ">
                                        {category?.isActive
                                          ? "Active"
                                          : "Inactive"}
                                      </label>
                                    </div>

                                    <hr className="my-4 border-b-1 border-slate-300" />

                                    <div className="flex items-center justify-between">
                                      <div className="mr-8">
                                        <label className="block uppercase text-slate-600 text-xs font-bold mb-2 mr-4">
                                          Created At
                                        </label>
                                        <label className="block  text-slate-600 text-xs font-bold mb-2">
                                          {" "}
                                          {moment
                                            .utc(category?.createdAt)
                                            .local()
                                            .format("LL")}{" "}
                                        </label>
                                      </div>
                                      <div className="">
                                        <label className="block uppercase text-slate-600 text-xs font-bold mb-2 mr-4">
                                          Updated At
                                        </label>
                                        <label className="block  text-slate-600 text-xs font-bold mb-2">
                                          {" "}
                                          {moment
                                            .utc(category?.updatedAt)
                                            .local()
                                            .format("LL")}{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <hr className="my-4 border-b-1 border-slate-300" />
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="flex items-center pt-8">
                                  {editing && (
                                    <button
                                      onClick={handleDelete}
                                      className=" bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-4"
                                      type="button"
                                    >
                                      Delete
                                    </button>
                                  )}
                                  <div className="ml-auto">
                                    <button
                                      onClick={closeModal}
                                      className=" bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 mr-4"
                                      type="button"
                                    >
                                      Cancel
                                    </button>

                                    <button
                                      onClick={handleSaveChanges}
                                      className="success-green text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150 ml-4"
                                      type="button"
                                      disabled={
                                        !category?.description ||
                                        !category?.name
                                      }
                                    >
                                      Save changes
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
