import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  GetFeedbackById,
  Acknowledge,
  Unacknowledge,
  MarkUnread,
} from ".././../store/actionCreators/feedback";
import Swal from "sweetalert2";

export default function Modal({ showModal, setShowModal, feedback }) {
  const selectedFeedback = useSelector(
    (state) => state.feedback.selectedFeedback
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      dispatch(GetFeedbackById(feedback?.id));
    }
  }, [showModal]);

  const handleAcknowledge = (e, id) => {
    e.preventDefault();

    const data = {
      id: selectedFeedback.id,
    };
    Swal.fire({
      title: "Acknowledge feedback?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
       
        dispatch(Acknowledge(id, data))
          .then(() => {
            Swal.fire(`Acknowledged!`, "", "success");
            dispatch(GetFeedbackById(feedback?.id));
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  const handleUnacknowledge = (e, id) => {
    e.preventDefault();
    
    const data = {
      id: selectedFeedback.id,
    };
    Swal.fire({
      title: "Unacknowledge feedback?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
       
        dispatch(Unacknowledge(id, data))
          .then(() => {
            Swal.fire(`Unacknowledge!`, "", "success");
            dispatch(GetFeedbackById(feedback?.id));
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  const markUnread = (e, id) => {
    e.preventDefault();
    
    const data = {
      id: selectedFeedback.id,
    };
    Swal.fire({
      title: "Mark feedback as unread?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
      
        dispatch(MarkUnread(id, data))
          .then(() => {
            Swal.fire(`Marked as unread!`, "", "success");
            dispatch(GetFeedbackById(feedback?.id));
            setShowModal(false);
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed top-10 mx-auto max-w-3xl feedback-modal">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
                <h3 className="text-xl font-medium">More info</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <div className="items-center p-4 border-collapse">
                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex items-center">
                    <h3 className="font-medium mr-2">Category:</h3>
                    <span className="mr-4">
                      {selectedFeedback?.categoryName}
                    </span>
                    <span className="bg-sky-400 text-white text-xs font-medium mr-2 px-2 py-1 rounded dark:bg-indigo-900 ">
                      Read{" "}
                    </span>                  
                  </div>
                  <div className="align-middle p-2 text-left leading-3 flex h-52">
                    <h3 className="font-medium mr-2">Message:</h3>
                    <span style={{ maxHeight: 200, overflowY: "auto" }}>
                      {selectedFeedback?.body}
                    </span>
                  </div>                 

                  <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex mb-4">
                    <h3 className="font-medium mr-2">Sent on:</h3>
                    <span>
                      {selectedFeedback.createdAt
                        ? moment
                            .utc(selectedFeedback.createdAt)
                            .local()
                            .format("LLL")
                        : "Never"}
                    </span>
                  </div>
                  {selectedFeedback?.acknowledgedAt ? (
                    <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex mb-4">
                      <h3 className="font-medium mr-2">Acknowledged at:</h3>
                      <span>
                        {selectedFeedback.acknowledgedAt
                          ? moment
                              .utc(selectedFeedback.acknowledgedAt)
                              .local()
                              .format("LLL")
                          : "Never"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {selectedFeedback?.readAt ? (
                    <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex mb-4">
                      <h3 className="font-medium mr-2">Read At:</h3>
                      <span>
                        {selectedFeedback.readAt
                          ? moment
                              .utc(selectedFeedback.readAt)
                              .local()
                              .format("LLL")
                          : "Never"}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex items-center">
                    {!selectedFeedback?.acknowledgedAt ? (
                      <button
                        onClick={(e) =>
                          handleAcknowledge(e, selectedFeedback.id)
                        }
                        className="success-green text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150 ml-auto"
                        type="button"
                      >
                        Acknowledge
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={(e) =>
                        handleUnacknowledge(e, selectedFeedback.id)
                      }
                      className="bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Unacknowledge
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
