import * as actionTypes from "../action-types";

let initialState = {
  paymentMethods: [],
  loading: false,
  error: null,
  selectedPaymentMethod: {},
  defaultPaymentMethod: {},
  paymentMethodAdded: false,
  stripeAccountAdded: false,
};
const paymentMethods = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload,
      };
    case actionTypes.FETCH_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.PAYMENT_METHOD_ADDED:
      return {
        ...state,
        loading: false,
        paymentMethodAdded: action.payload,
      };
    case actionTypes.STRIPE_ACCOUNT_ADDED:
      return {
        ...state,
        loading: false,
        stripeAccountAdded: action.payload,
      };

    // case actionTypes.FETCH_PAYMENT_METHOD_BY_ID_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case actionTypes.FETCH_PAYMENT_METHOD_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     selectedPaymentMethod: action.payload,
    //   };
    // case actionTypes.FETCH_PAYMENT_METHOD_BY_ID_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //   };
    // case actionTypes.FETCH_DEFAULT_PAYMENT_METHOD_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case actionTypes.FETCH_DEFAULT_PAYMENT_METHOD_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     defaultPaymentMethod: action.payload,
    //   };
    // case actionTypes.FETCH_DEFAULT_PAYMENT_METHOD_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: true,
    //   };
    default:
      return state;
  }
};

export default paymentMethods;
