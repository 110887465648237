import * as actionTypes from "../action-types";

let initialState = {
  allTickets: [],
  vendorsFeetback: [],
  loading: false,
  error: null,
  selectedTicket: {},
  riskLevel: {},
  ticketTypes: [],
  ticketComments: {},
  ticketTypesActions: [],
  userProductLimits: [],
  selectedFeedback: {},
};
const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FEEDBACK_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_FEEDBACK_SUCCESS:
      return {
        ...state,
        allTickets: action.payload,
      };
    case actionTypes.FETCH_FEEDBACK_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_FEEDBACK_BY_ID_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_FEEDBACK_BY_ID_SUCCESS:
      return {
        ...state,
        selectedFeedback: action.payload,
      };
    case actionTypes.FETCH_FEEDBACK_BY_ID_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default ticketsReducer;
