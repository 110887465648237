import React, { useState } from "react";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";

function TextEditor(props) {
  return (
    <>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4">
              <div className="relative w-full mb-3 mt-8">
                {/* <label className="block mb-2 text-sm font-medium text-blueGray-500">
                  Upload file
                </label>
                <input
                  className="block w-full text-md text-slate-600 bg-white rounded shadow focus:ring ease-linear transition-all duration-150 cursor-pointer focus:outline-none"
                  id="file_input"
                  type="file"
                />
                <p class="mt-1 text-sm text-blueGray-500">PDF file</p> */}

                <Editor
                  value={props.text}
                  onEditorChange={(newText) => props.setText(newText)}
                  init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                      // "paste",
                      "powerpaste",
                    ],
                    // paste_preprocess: function (plugin, args) {
                    //   // replace copied text with empty string
                    //   args.stopImmediatePropagation();
                    //   args.stopPropagation();
                    //   args.preventDefault();
                    //   Swal.fire(
                    //     "",
                    //     "Please edit the changes, paste function disabled to save formatting.",
                    //     "info"
                    //   );
                    // },
                    toolbar:
                      "undo redo | formatselect | paste | " +
                      "bold italic backcolor | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | help",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    // smart_paste: true,
                    paste_as_text: true,
                    contextmenu: 'link paste'
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default TextEditor;
