import * as actionTypes from "../action-types";
// import Axios from "axios";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const GetVendorFeedback =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_FEEDBACK_REQUEST });

    try {
      const res = await Axios.get(
        `/platformfeedback/vendor?${query}&Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_FEEDBACK_SUCCESS,
        payload: res.data,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_FEEDBACK_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetCustomerFeedback =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_FEEDBACK_REQUEST });

    try {
      const res = await Axios.get(
        `/platformfeedback/customer?${query}&Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_FEEDBACK_SUCCESS,
        payload: res.data,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_FEEDBACK_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetFeedbackById = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_FEEDBACK_BY_ID_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/platformfeedback/${id}`);
    dispatch({
      type: actionTypes.FETCH_FEEDBACK_BY_ID_SUCCESS,
      payload: res.data,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    dispatch({
      type: actionTypes.FETCH_FEEDBACK_BY_ID_FAILURE,
      error,
    });
  }
};

export const Acknowledge = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post(`/platformfeedback/${id}/acknowledge`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const Unacknowledge = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post(
          `/platformfeedback/${id}/Unacknowledge`,
          data
        );
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};
export const MarkUnread = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post(`/platformfeedback/${id}/markunread`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};
