import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  ApproveAd,
  DeclineAd,
  GetPendingAds,
} from ".././../store/actionCreators/settings";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { formatISODateWithoutTime } from "../../utils/common";

export default function Modal({
  showModal,
  setShowModal,
  selectedAd,
  setSelectedAd,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();

  const handleApprove = (e, id) => {
    e.preventDefault();
    let sDate = formatISODateWithoutTime(startDate);
    let eDate = formatISODateWithoutTime(endDate);

    const data = {
      advertId: selectedAd.advertId,
      startDate: sDate,
      endDate: eDate,
    };

    Swal.fire({
      title: "Approve ad?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(ApproveAd(data))
          .then(() => {
            Swal.fire(`Approved!`, "", "success");
            dispatch(GetPendingAds());
            setShowModal(false);
            setEndDate(null);
            setStartDate(null);
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  const handleDecline = (e) => {
    e.preventDefault();
    const id = selectedAd.advertId;
    const data = {
      advertId: selectedAd.advertId,
    };

    Swal.fire({
      title: "Decline ad?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeclineAd(id, data))
          .then(() => {
            Swal.fire(`Ad Declined!`, "", "success");
            dispatch(GetPendingAds());
            setShowModal(false);
            setEndDate(null);
            setStartDate(null);
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  const onClose = () => {
    setShowModal(false);
    setEndDate(null);
    setStartDate(null);
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            className="overflow-y-auto overflow-x-auto top-0 right-0 left-0 z-20 w-full
            md:inset-0 h-modal md:h-full bg-gray-600/25 fixed z-50 ads-modal-container"
          >
            <div className="h-screen w-screen flex justify-center items-center">
              <div className="w-full p-12 lg:px-48 h-full max-h-full md:h-auto flex justify-center">
                <div className="fixed top-10 mx-auto max-w-3xl ads-modal h-[80vh] ">
                  {/*content*/}
                  <div className="border-0 rounded shadow-2xl relative flex flex-col w-full  outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t bg-slate-200">
                      <h3 className="text-xl font-medium">Post Ad</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        // onClick={() => setShowModal(false)}
                        onClick={() => onClose()}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}

                    <>
                      <div className="flex flex-wrap bg-slate-100 py-6 2xl:w-8/12">
                        <div className="w-full  px-4 mx-auto">
                          <div className="relative flex flex-col min-w-0 break-words  mb-6  border-0">
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                              <form>
                                <h6 className="text-slate-400 text-sm mt-3 mb-2 font-bold uppercase">
                                  Vendor
                                </h6>
                                <div className="flex flex-wrap">
                                  <div className="w-full px-4 flex items-center">
                                    <p>{selectedAd?.name}</p>
                                    <span className="ml-auto ml-8">
                                      {" "}
                                      {selectedAd?.email}{" "}
                                    </span>
                                  </div>
                                </div>
                                <hr className="mt-6 border-b-1 border-slate-300" />
                                <h6 className="text-slate-400 text-sm mt-3 mb-2 font-bold uppercase">
                                  Submitted At
                                </h6>
                                <div className="w-full px-4">
                                  <p>
                                    {" "}
                                    {moment
                                      .utc(selectedAd.submittedAt)
                                      .local()
                                      .format("LLL")}{" "}
                                  </p>
                                </div>

                                <hr className="mt-6 border-b-1 border-slate-300" />

                                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                                  DESCRIPTION
                                </h6>
                                <div className="flex flex-wrap">
                                  <div className="w-full lg:w-12/12 px-4">
                                    <div className="relative w-full mb-3">
                                      <textarea
                                        type="text"
                                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="Type here"
                                        rows="4"
                                        value={selectedAd?.comment}
                                        onChange={(e) => {
                                          setSelectedAd({
                                            ...selectedAd,
                                            comment: e.target.value,
                                          });
                                          // checkCompleted();
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>

                                <hr className="mt-6 border-b-1 border-slate-300" />

                                <h6 className="text-slate-400 text-sm mt-3 mb-6 font-bold uppercase">
                                  Date
                                </h6>
                                <div className="flex flex-wrap">
                                  <div className="w-full md:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                      <label
                                        className="block uppercase text-slate-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                      >
                                        Start Date
                                      </label>
                                      <DatePicker
                                        value={startDate}
                                        className="w-full"
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        minDate={new Date()}
                                        maxDate={endDate ? endDate : ""}
                                        isClearable
                                      />
                                    </div>
                                  </div>

                                  <div className="w-full md:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                      <label
                                        className="block uppercase text-slate-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                      >
                                        End Date
                                      </label>
                                      <DatePicker
                                        value={endDate}
                                        className="w-full"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        minDate={
                                          startDate ? startDate : new Date()
                                        }
                                        isClearable
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="flex items-center pt-8">
                                  <button
                                    onClick={handleDecline}
                                    className="bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                  >
                                    Decline
                                  </button>

                                  <button
                                    onClick={handleApprove}
                                    className="success-green text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150 ml-auto"
                                    type="button"
                                    disabled={!startDate || !endDate}
                                  >
                                    Approve
                                  </button>

                                  <button
                                    onClick={() => onClose()}
                                    className="active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 "
                                    type="button"
                                  >
                                    Close
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
