import * as actionTypes from "../action-types";

let initialState = {
  salesAdmins: [],
  vendors: [],
  searchedVendors: [],
  vendorsBySalesAdmin: [],
  customers: [],
  loading: false,
  error: null,
  selectedVendor: {},
  riskLevel: {},
  latestRiskAssessment: {},
  userRiskLevelHistory: [],
  userProductLimits: [],
  showPagination: true,
  service: {},
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SALES_ADMINS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_SALES_ADMINS_SUCCESS:
      return {
        ...state,
        salesAdmins: action.payload,
      };
    case actionTypes.FETCH_SALES_ADMINS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_VENDOR_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_VENDOR_SUCCESS:
      return {
        ...state,
        selectedVendor: action.payload,
      };
    case actionTypes.FETCH_VENDOR_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_VENDORS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: action.payload,
      };
    case actionTypes.FETCH_VENDORS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_SEARCH_VENDORS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_SEARCH_VENDORS_SUCCESS:
      return {
        ...state,
        searchedVendors: action.payload,
      };
    case actionTypes.FETCH_SEARCH_VENDORS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_SUCCESS:
      return {
        ...state,
        vendorsBySalesAdmin: action.payload,
      };
    case actionTypes.FETCH_VENDORS_BY_SALES_ADMIN_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
      };
    case actionTypes.FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.SHOW_PAGINATION:
      return {
        ...state,
        showPagination: action.payload,
      };
    case actionTypes.FETCH_SERVICE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
      };
    case actionTypes.FETCH_SERVICE_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default userReducer;
