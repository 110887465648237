import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
// components
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../src/style.css";
import Pagination from "../../components/Pagination/";
import { GetRevenueAnalytics } from ".././../store/actionCreators/analytics";

export default function CardTable(props) {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(5);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetRevenueAnalytics(page, limit));
  }, [page, limit]);

  const state = useSelector((state) => state.analytics.revenueAnalytics);
  const tableHead = ["Vendor Name", "Revenue"];

  return (
    <>
      <div
        className={
          "relative pb-2 flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
            </div>
          </div>
        </div>
        <div className="block w-full justify-between">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {tableHead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                    >
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {state?.items ? (
                state?.items.map((notification, i) => {
                  return (
                    <tr key={i}>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center ">
                        <span
                          className={
                            "ml-3 font-bold " +
                            +(props.color === "light"
                              ? "text-slate-600"
                              : "text-white")
                          }
                        >
                          {notification.vendorName}
                        </span>
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        $ {notification.vendorRevenue}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          page={page}
          onPage={state.currentPage}
          setPage={setPage}
          totalPages={state?.totalPages}
        />
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
