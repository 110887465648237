import * as actionTypes from "../action-types";

let initialState = {
  revenueAnalytics: {},
  cancellationsAnalytics: {},
  loading: false,
  error: null,
  viewsAnalytics: {},
  adminrevenue: 0,
  totalusers: 0,
  totalvendors: 0,
  vendorrevenue: 0,
};
const lossLimitNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REVENUE_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_REVENUE_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueAnalytics: action.payload,
      };
    case actionTypes.FETCH_REVENUE_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_VIEWS_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_VIEWS_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        viewsAnalytics: action.payload,
      };
    case actionTypes.FETCH_VIEWS_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_CANCELLATION_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_CANCELLATION_ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        cancellationsAnalytics: action.payload,
      };
    case actionTypes.FETCH_CANCELLATION_ANALYTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_ADMIN_REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_ADMIN_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        adminrevenue: action.payload,
      };
    case actionTypes.FETCH_ADMIN_REVENUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_TOTAL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_TOTAL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalusers: action.payload,
      };
    case actionTypes.FETCH_TOTAL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_TOTAL_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_TOTAL_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalvendors: action.payload,
      };
    case actionTypes.FETCH_TOTAL_VENDORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.FETCH_VENDOR_REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_VENDOR_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorrevenue: action.payload,
      };
    case actionTypes.FETCH_VENDOR_REVENUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default lossLimitNotificationsReducer;
