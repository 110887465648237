import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  GetVendor,
  ApproveVendorProfile,
  DeclineVendorProfile,
  DeactivateVendor,
  ActivateVendor,
  Test,
  TestTwo,
} from "../../../store/actionCreators/users";
import { GetVendorRevenue } from "../../../store/actionCreators/analytics";
import moment from "moment";
import Swal from "sweetalert2";
import * as actionTypes from "../../../store/action-types";
import {
  getFormatedHour12,
  formatDateOnlyShort,
  formatPhoneNumber,
} from "../../../utils/common";
import Service from "./Service";
import DatePicker from "react-datepicker";
import CardDashboardAnalytics from "../../../components/Cards/CardDashboardAnalytics.js";
import Rating from "../../../components/Rating";
import NoDataPlaceholder from "../../../assets/svg/no_data_image_placeholder.svg";
import Products from "./Products";

const options = [
  {
    value: 0,
    label: "All",
  },
  {
    value: 1,
    label: "Company Logo",
  },
  {
    value: 2,
    label: "Additional Image",
  },
  {
    value: 3,
    label: "Business Description",
  },
  {
    value: 4,
    label: "Business Hours",
  },
  {
    value: 5,
    label: "Cancellation Policy",
  },
  {
    value: 6,
    label: "Qualification",
  },
  // {
  //   value: 7,
  //   label: "Shipping Information",
  // },
  {
    value: 8,
    label: "Business Address",
  },
];

const approveBtnCls =
  "bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150  mx-4";

const declineBtnCls =
  "text-white bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 ";

export default function VendorDetails({ page, setPage }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vendor = useSelector((state) => state.users.selectedVendor);
  const [selectedOption, setSelectedOption] = useState({ value: 0 });
  const [declinedOption, setDeclinedOption] = useState({ value: 0 });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedVendor, setSelectedVendor] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [approveOptions, setApproveOptions] = useState([
    { label: "Approve", value: "Approve" },
    { label: "Decline", value: "Decline" },
  ]);
  const [selectedApproveOption, setSelectedApproveOption] = useState({
    label: "Approve",
    value: "Approve",
  });

  const tabItems = [
    { title: "Profile", value: 1 },
    { title: "Services", value: 2 },
    { title: "Products", value: 3 },
  ];

  let fromDate = formatDateOnlyShort(startDate);
  let toDate = formatDateOnlyShort(endDate);

  const user = useSelector((state) => state.account.userInfo);
  const revenue = useSelector((state) => state.analytics.vendorrevenue);

  let isAdmin =
    user?.roles?.includes("administrator") ||
    user?.roles?.includes("superadministrator");

  const Badge = () => {
    let badge = (
      <span className="text-xs text-white bg-emerald-400 rounded-md py-1 px-2 font-semibold ml-2">
        Edited
      </span>
    );

    return badge;
  };

  const handleApproveChange = (event) => {
    setSelectedApproveOption({ value: event.target.value });
  };

  function statusDetails(s) {
    let text = "";
    let cls = "bg-gray text-grayDark";
    switch (s) {
      case "APPROVED":
        text = "Approved";
        cls = "bg-emerald-400 text-white px-2";
        break;
      case "WAITING_FOR_APPROVAL":
        text = "Waiting for approval";
        cls = "bg-orange-200 text-orange-500 px-2";
        break;
      case "DECLINED":
        text = "Declined";
        cls = "bg-red-100 text-red-500";
        break;
    }
    return { text: text, cls: cls };
  }

  function ProfileStatus({ s }) {
    let detail = statusDetails(s);
    return (
      <span
        className={`rounded text-xs h-fit uppercase px-1 font-bold whitespace-nowrap ${detail.cls}`}
      >
        {detail.text}
      </span>
    );
  }

  useEffect(() => {
    dispatch(GetVendor(id));
  }, []);

  useEffect(() => {}, [tabItems]);
  useEffect(() => {}, [approveOptions]);

  useEffect(() => {
    if (vendor) setSelectedVendor(vendor);
  }, [vendor]);

  useEffect(() => {}, [
    selectedVendor?.certifications,
    selectedVendor?.services,
  ]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    } else {
      if (startDate || endDate) {
        const timeoutId = setTimeout(() => {
          dispatch(GetVendorRevenue(fromDate, toDate, id));
        }, 800);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetVendorRevenue(fromDate, toDate, id));
      }
    }
  }, [isAdmin, startDate, endDate]);

  const thead = ["Day of the week", "From", "", "To"];

  const handleBlock = (e, id) => {
    e.preventDefault();
    const data = {
      vendorProfileId: selectedVendor.userId,
    };
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (selectedVendor?.deactivatedAt) {
          dispatch(ActivateVendor(id, data))
            .then(() => {
              Swal.fire(`Reactivated!`, "", "success");
            })
            .catch((error) => {
              console.log("error", error.response);
            });
        } else {
          dispatch(DeactivateVendor(id, data))
            .then((res) => {
              Swal.fire(`Deactivated!`, "", "success");
            })
            .catch((error) => {
              console.log("error", error.response);
            });
        }
      }
    });
  };

  const handleChange = (event) => {
    setSelectedOption({ value: event.target.value });
  };

  const handleDeclineChange = (event) => {
    setDeclinedOption({ value: event.target.value });
  };

  const approveSingleChange = (section, code) => {
    const data = {
      userId: selectedVendor.userId,
      section: code,
    };
    // setSelectedOption({ value: code });
    console.log("section", section, code);
    console.log("data", data);
    // Swal.fire(`Approve ${section}?`, "", "success");
    Swal.fire({
      title: `Approve ${section}?`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#0ea5e9",
      confirmButtonText: "Approve",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(ApproveVendorProfile(selectedVendor.userId, code, data))
          .then((res) => {
            if (res) {
              Swal.fire(`Profile change approved!`, "", "success");
              dispatch(GetVendor(selectedVendor.userId));
            }
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };
  const declineSingleChange = (section, code) => {
    const data = {
      userId: selectedVendor.userId,
      section: code,
    };
    Swal.fire({
      title: `Decline ${section}?`,
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonColor: "#0ea5e9",
      confirmButtonText: "Decline",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeclineVendorProfile(selectedVendor.userId, code, data))
          .then((res) => {
            if (res) {
              Swal.fire(`Profile changes declined!`, "", "success");
              dispatch(GetVendor(selectedVendor.userId));
            }
          })
          .catch((error) => {
            console.log("error", error.response);
          });
      }
    });
  };

  const approveChanges = (event) => {
    event.preventDefault();
    const id = selectedVendor.userId;
    const section = selectedOption.value;
    const data = {
      userId: selectedVendor.userId,
      section: selectedOption.value,
    };

    dispatch(ApproveVendorProfile(id, section, data))
      .then((res) => {
        if (res) {
          Swal.fire(`Profile changes approved!`, "", "success");
          dispatch(GetVendor(id));
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const declineChanges = (event) => {
    event.preventDefault();
    const id = selectedVendor.userId;
    const section = declinedOption.value;
    const data = {
      userId: selectedVendor.userId,
      section: declinedOption.value,
    };

    dispatch(DeclineVendorProfile(id, section, data))
      .then((res) => {
        if (res) {
          Swal.fire(`Profile changes declined!`, "", "success");
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const backButton = () => {
    navigate(`/admin/vendors`);
    dispatch({ type: actionTypes.FETCH_VENDOR_SUCCESS, payload: {} });
  };

  const onPageChange = (value) => {
    setActiveTab(value);
  };

  function SingleApprove({
    label,
    value,
    approveSingleChange,
    declineSingleChange,
    approveBtnCls,
    declineBtnCls,
  }) {
    return (
      <div className="single-approve">
        <button
          className={approveBtnCls}
          onClick={() => approveSingleChange(label, value)}
        >
          Approve
        </button>
        <button
          className={declineBtnCls}
          onClick={() => declineSingleChange(label, value)}
        >
          Decline
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-12/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6  rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center md:flex justify-between items-center">
                <div
                  className="flex items-baseline"
                  style={{ alignItems: "baseline" }}
                >
                  <div className="text-left">
                    <h6 className="text-slate-700 text-2xl font-bold">
                      {selectedVendor?.businessName}
                    </h6>
                    <ProfileStatus s={selectedVendor?.approvalStatus} />
                  </div>
                  <span className="px-4 text-left whitespace-nowrap">
                    {" "}
                    <Rating rating={selectedVendor?.averageRatings} />
                  </span>
                </div>
                <div className="flex items-start pt-6 md:pt-0">
                  <Link to={"/admin/vendors"}>
                    <button className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 text-left whitespace-nowrap">
                      Go back
                    </button>
                  </Link>
                  <button
                    onClick={(e) => handleBlock(e, selectedVendor.id)}
                    className={
                      selectedVendor?.deactivatedAt
                        ? "bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        : "bg-emerald-400" +
                          " text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    }
                    type="button"
                  >
                    {selectedVendor?.deactivatedAt ? "Activate" : "Deactivate"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap my-6">
              {tabItems &&
                tabItems.map((item, index) => (
                  <div className="w-full lg:w-4/12 " key={index}>
                    <div
                      onClick={() => onPageChange(item.value)}
                      className={`bg-gray-100 text-slate-600 active:bg-sky-600 font-bold w-full uppercase text-sm px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 cursor-pointer text-center ${
                        item.value == activeTab && "bg-indigo-600 text-white"
                      }`}
                    >
                      {item.title}
                    </div>
                  </div>
                ))}
            </div>
            {activeTab === 1 ? (
              <div className="bg-white rounded-lg">
                <div className="xl:flex ">
                  <div className="w-full px-4 xl:px-10 pt-0  ">
                    <h6 className="text-slate-400 text-sm mt-3  font-bold uppercase"></h6>
                    <div className="flex flex-wrap items-center text-sm md:text-base">
                      <div className="relative flex flex-col min-w-0 break-words  w-full mb-6">
                        <div className="rounded-t mb-0 px-2 py-3 border-0">
                          <div className="flex flex-wrap items-center text-sm md:text-base">
                            <div className="items-center w-full bg-transparent border-collapse">
                              <div className="align-middle whitespace-nowrap p-2 text-left leading-3 border-b">
                                {selectedVendor?.approvalStatus ===
                                "WAITING_FOR_APPROVAL" ? (
                                  <div className="border-b text-left leading-3 py-4 flex mb-4 justify-between">
                                    <h3 className="font-medium mr-2 mb-2 text-xl">
                                      All Changes
                                    </h3>
                                    <SingleApprove
                                      label="All Changes"
                                      value={0}
                                      approveSingleChange={approveSingleChange}
                                      declineSingleChange={declineSingleChange}
                                      approveBtnCls={approveBtnCls}
                                      declineBtnCls={declineBtnCls}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="md:grid grid-cols-6 gap-6">
                                  <div className="col-span-3 ">
                                    {/* <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex"> */}
                                    <h3 className="font-medium mr-2 mb-2">
                                      {`${selectedVendor?.firstName} ${selectedVendor?.lastName}`}
                                    </h3>
                                    {/* </div>                                    */}
                                    <div className="align-middle whitespace-nowrap py-2 text-left leading-3 flex ">
                                      <h3 className="font-medium mr-2">
                                        Subscription Plan:
                                      </h3>
                                      <span>
                                        {
                                          selectedVendor?.vendorSubscription
                                            ?.businessPlanName
                                        }
                                      </span>
                                    </div>
                                    <div className="align-middle whitespace-nowrap py-2 text-left leading-3 flex ">
                                      <h3 className="font-medium mr-2">
                                        Billing Period:
                                      </h3>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {
                                          selectedVendor?.vendorSubscription
                                            ?.billingPeriod
                                        }
                                      </span>
                                    </div>
                                    <div className="align-middle whitespace-nowrap py-2 text-left leading-3 flex ">
                                      <h3 className="font-medium mr-2">
                                        Contact Email:
                                      </h3>
                                      <span>
                                        {selectedVendor?.contactEmail}
                                      </span>
                                    </div>
                                    <div className="align-middle whitespace-nowrap py-2 text-left leading-3 flex ">
                                      <h3 className="font-medium mr-2">
                                        Phone number:
                                      </h3>
                                      {selectedVendor?.changes?.profile
                                        ?.phoneNumber ? (
                                        <div>
                                          <span>
                                            {formatPhoneNumber(
                                              selectedVendor?.changes?.profile
                                                ?.phoneNumber
                                            )}
                                          </span>{" "}
                                          <Badge />
                                        </div>
                                      ) : (
                                        <span>
                                          {formatPhoneNumber(
                                            selectedVendor?.phoneNumber
                                          )}
                                        </span>
                                      )}
                                    </div>
                                    {selectedVendor?.website &&
                                      selectedVendor?.website !== "" && (
                                        <div className="align-middle py-2 text-left leading-3 flex ">
                                          <h3 className="font-medium mr-2">
                                            Website:
                                          </h3>
                                          {selectedVendor?.changes?.profile
                                            ?.website ? (
                                            <div>
                                              <span>
                                                {
                                                  selectedVendor?.changes
                                                    ?.profile?.website
                                                }
                                              </span>{" "}
                                              <Badge />
                                            </div>
                                          ) : (
                                            <span>
                                              {selectedVendor?.website}
                                            </span>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                  {selectedVendor?.changes?.companyLogo ? (
                                    <div className="flex items-start col-span-3 justify-between">
                                      <div className="flex justify-start items-start relative">
                                        <img
                                          src={
                                            selectedVendor?.changes?.companyLogo
                                              ?.url
                                          }
                                          className="w-20 h-20 object-cover rounded-lg mr-2"
                                          style={{ objectFit: "cover" }}
                                        />
                                        <div className="absolute right-0">
                                          <Badge />
                                        </div>
                                      </div>
                                      <SingleApprove
                                        label="Company Logo"
                                        value={1}
                                        approveSingleChange={
                                          approveSingleChange
                                        }
                                        declineSingleChange={
                                          declineSingleChange
                                        }
                                        approveBtnCls={approveBtnCls}
                                        declineBtnCls={declineBtnCls}
                                      />
                                    </div>
                                  ) : (
                                    <div className="col-span-3">
                                      <img
                                        src={selectedVendor?.companyLogo?.url}
                                        className="w-20 h-20 object-cover rounded-lg"
                                        style={{
                                          objectFit: "cover",
                                          width: "120px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="border-b p-2 text-left leading-3 py-4">
                                <h3 className="font-medium mr-2">
                                  Business Name:
                                </h3>
                                {selectedVendor?.changes?.profile
                                  ?.businessName ? (
                                  <div className="flex justify-between items-center py-4">
                                    <div>
                                      <span>
                                        {
                                          selectedVendor?.changes?.profile
                                            ?.businessName
                                        }
                                      </span>{" "}
                                      <Badge />
                                    </div>
                                    <SingleApprove
                                      label="Business Name"
                                      value={9}
                                      approveSingleChange={approveSingleChange}
                                      declineSingleChange={declineSingleChange}
                                      approveBtnCls={approveBtnCls}
                                      declineBtnCls={declineBtnCls}
                                    />
                                  </div>
                                ) : (
                                  <div className="py-4">
                                    {selectedVendor?.businessName}
                                  </div>
                                )}
                              </div>

                              <div className="border-b p-2 text-left leading-3 py-4">
                                <h3 className="font-medium mr-2">
                                  Business Description:
                                </h3>

                                {selectedVendor?.changes?.profile
                                  ?.businessDescription ? (
                                  <div className="flex justify-between items-center py-4">
                                    <div>
                                      <span>
                                        {
                                          selectedVendor?.changes?.profile
                                            ?.businessDescription
                                        }
                                      </span>{" "}
                                      <Badge />
                                    </div>
                                    <SingleApprove
                                      label="Business Description"
                                      value={3}
                                      approveSingleChange={approveSingleChange}
                                      declineSingleChange={declineSingleChange}
                                      approveBtnCls={approveBtnCls}
                                      declineBtnCls={declineBtnCls}
                                    />
                                  </div>
                                ) : (
                                  <span>
                                    {selectedVendor?.businessDescription}
                                  </span>
                                )}
                              </div>

                              <div className="border-b p-2 text-left leading-3 py-4">
                                <h3 className="font-medium mr-2">Address:</h3>
                                <span>
                                  {selectedVendor?.changes?.address ? (
                                    <div className="flex justify-between items-center py-4">
                                      <div>
                                        <span>
                                          {
                                            selectedVendor?.changes?.address
                                              ?.street
                                          }
                                          {", "}
                                          {
                                            selectedVendor?.changes?.address
                                              ?.city
                                          }
                                          {", "}
                                          {
                                            selectedVendor?.changes?.address
                                              ?.province
                                          }
                                          {", "}
                                          {
                                            selectedVendor?.changes?.address
                                              ?.postalCode
                                          }
                                        </span>
                                        <Badge />
                                      </div>
                                      <SingleApprove
                                        label="Address"
                                        value={8}
                                        approveSingleChange={
                                          approveSingleChange
                                        }
                                        declineSingleChange={
                                          declineSingleChange
                                        }
                                        approveBtnCls={approveBtnCls}
                                        declineBtnCls={declineBtnCls}
                                      />
                                    </div>
                                  ) : (
                                    <span>
                                      {selectedVendor?.address?.street}
                                      {", "}
                                      {selectedVendor?.address?.city}
                                      {", "}
                                      {selectedVendor?.address?.province}
                                      {", "}
                                      {selectedVendor?.address?.postalCode}
                                    </span>
                                  )}
                                </span>
                              </div>

                              <div className="border-b p-2 text-left leading-3 py-4 ">
                                <h3 className="font-medium mr-2 mb-2">
                                  Certifications:
                                </h3>
                                {selectedVendor?.changes?.certifications ? (
                                  <div className="flex items-start w-full justify-between">
                                    {/* <div className="grid grid-cols-12"> */}
                                    <div className="col-span-8 md:col-span-6 flex items-start">
                                      <textarea
                                        className="w-full"
                                        disabled
                                        value={
                                          selectedVendor?.changes
                                            ?.certifications || ""
                                        }
                                        defaultValue=""
                                        rows="4"
                                      />
                                      <Badge />
                                    </div>
                                    <div className="col-span-4 md:col-span-6 ml-auto">
                                      <SingleApprove
                                        label="Certifications"
                                        value={6}
                                        approveSingleChange={
                                          approveSingleChange
                                        }
                                        declineSingleChange={
                                          declineSingleChange
                                        }
                                        approveBtnCls={approveBtnCls}
                                        declineBtnCls={declineBtnCls}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <textarea
                                    className="w-full"
                                    disabled
                                    value={selectedVendor?.certifications || ""}
                                    defaultValue=""
                                    rows="4"
                                  />
                                )}
                              </div>
                              {/* <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex mt-2">
                                <h3 className="font-medium mr-2">
                                  Cancellation Charge:
                                </h3>
                                <span>
                                  {selectedVendor?.cancellationCharge
                                    ? `$${selectedVendor?.cancellationCharge}`
                                    : "0"}
                                </span>
                              </div> */}
                              <div className="p-2 text-left align-middle whitespace-nowrap leading-3 flex mt-2">
                                <h3 className="font-medium mr-2">
                                  Cancellation Charge:
                                </h3>
                                {selectedVendor?.changes?.cancellationFee ? (
                                  <div className="flex justify-between items-center w-full">
                                    <span>
                                      $
                                      {selectedVendor?.changes?.cancellationFee}
                                    </span>{" "}
                                    <Badge />
                                    <div className="ml-auto">
                                      <SingleApprove
                                        label="Cancellation Charge"
                                        value={5}
                                        approveSingleChange={
                                          approveSingleChange
                                        }
                                        declineSingleChange={
                                          declineSingleChange
                                        }
                                        approveBtnCls={approveBtnCls}
                                        declineBtnCls={declineBtnCls}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <span>
                                    {selectedVendor?.cancellationCharge
                                      ? `$${selectedVendor?.cancellationCharge}`
                                      : "No cancellation charge"}
                                  </span>
                                )}
                              </div>
                              <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                                <h3 className="font-medium mr-2">
                                  Accepted Privacy Policy:
                                </h3>
                                <span>
                                  {selectedVendor?.acceptPrivacyPolicy
                                    ? "Yes"
                                    : "No"}
                                </span>
                              </div>
                              <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                                <h3 className="font-medium mr-2">
                                  Accepted Terms of Services:
                                </h3>
                                <span>
                                  {selectedVendor?.acceptTOS ? "Yes" : "No"}
                                </span>
                              </div>

                              {selectedVendor?.createdAt && (
                                <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                                  <h3 className="font-medium mr-2">
                                    Created At:
                                  </h3>
                                  <span>
                                    {moment(selectedVendor?.createdAt).format(
                                      "LLL"
                                    )}
                                  </span>
                                </div>
                              )}

                              {selectedVendor?.deactivatedAt && (
                                <div className="align-middle whitespace-nowrap p-2 text-left leading-3 flex ">
                                  <h3 className="font-medium mr-2">
                                    Blocked At:
                                  </h3>
                                  <span>
                                    {selectedVendor?.deactivatedAt
                                      ? moment(
                                          selectedVendor?.deactivatedAt
                                        ).format("LLL")
                                      : "Not Blocked"}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="block xl:hidden"></hr>
                </div>

                <div className="align-middle whitespace-nowrap p-8 text-left leading-3 bg-white mb-4">
                  <div className="flex justify-between items-start">
                    <h3 className="font-medium mr-2">Profile Images</h3>
                    {selectedVendor?.changes?.profileImages &&
                    selectedVendor?.changes?.profileImages?.length > 0 ? (
                      <SingleApprove
                        label="Profile Images"
                        value={2}
                        approveSingleChange={approveSingleChange}
                        declineSingleChange={declineSingleChange}
                        approveBtnCls={approveBtnCls}
                        declineBtnCls={declineBtnCls}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className=" gap-6 py-4 flex flex-wrap">
                    {selectedVendor?.changes?.profileImages &&
                      selectedVendor?.changes?.profileImages.map(
                        (image, index) => {
                          return (
                            <div
                              key={index}
                              className="col-span-1 flex items-start relative"
                            >
                              <img
                                src={image.url}
                                className="w-40 h-40 object-cover rounded-lg mr-4"
                                style={{
                                  objectFit: "cover",
                                  height: 180,
                                  width: 180,
                                }}
                              />
                              <div className="absolute right-0">
                                <Badge />
                              </div>
                            </div>
                          );
                        }
                      )}
                    {selectedVendor?.profileImages?.map((image, index) => {
                      return (
                        <div key={index} className="col-span-1">
                          <img
                            src={image.url}
                            className="w-40 h-40 object-cover rounded-lg mr-2"
                            style={{
                              objectFit: "cover",
                              height: 180,
                              width: 180,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                {selectedVendor?.vendorSubscription?.businessPlanName ===
                  "Elite" && (
                  <div className="align-middle whitespace-nowrap p-8 text-left leading-3 bg-white ">
                    <h3 className="font-medium mr-2">Profile Video</h3>
                    <div className=" gap-6 py-4 flex items-center justify-center">
                      {selectedVendor?.changes?.vendorVideo &&
                        selectedVendor?.changes?.profileImages.map(
                          (image, index) => {
                            return (
                              <div key={index} className="col-span-1">
                                <Badge />
                                <img
                                  src={image.url}
                                  className="w-40 h-40 object-cover rounded-lg mr-2"
                                  style={{
                                    objectFit: "cover",
                                    height: 180,
                                    width: 180,
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {selectedVendor?.vendorVideo ? (
                        <video
                          // className={`pb-2 cursor-pointer py-8 ${
                          //   source ? "hidden" : "block"
                          // }`}
                          // width={500}
                          // width="100%"
                          // height="auto"
                          height={500}
                          controls
                          src={selectedVendor?.vendorVideo?.url}
                        />
                      ) : (
                        <img
                          className=""
                          src={
                            require("../../../assets/svg/no_data_image_placeholder.svg")
                              .default
                          }
                          alt="no_data_image_placeholder"
                        />
                      )}
                    </div>
                  </div>
                )}

                {selectedVendor?.approvalStatus === "WAITING_FOR_APPROVAL" ? (
                  <div className="border-b p-8 text-left leading-3 py-4 flex mb-4">
                    <h3 className="font-medium mr-2 mb-2">All Changes</h3>
                    <SingleApprove
                      label="All Changes"
                      value={0}
                      approveSingleChange={approveSingleChange}
                      declineSingleChange={declineSingleChange}
                      approveBtnCls={approveBtnCls}
                      declineBtnCls={declineBtnCls}
                    />
                  </div>
                ) : (
                  ""
                )}
                {isAdmin ? (
                  <div className="w-full px-4 xl:px-10 py-10 ">
                    <div className="w-full ">
                      <div className="lg:flex flex-wrap pb-8">
                        <div className="w-full xl:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-slate-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Start Date
                            </label>
                            <DatePicker
                              value={startDate}
                              className="w-full rounded-lg border-0 shadow"
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              // minDate={new Date()}
                              maxDate={endDate ? endDate : new Date()}
                              isClearable
                            />
                          </div>
                        </div>

                        <div className="w-full xl:w-6/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-slate-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              End Date
                            </label>
                            <DatePicker
                              value={endDate}
                              className="w-full rounded-lg border-0 shadow"
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              minDate={startDate ? startDate : ""}
                              maxDate={new Date()}
                              isClearable
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-full 2xl:w-6/12 mb-12 px-4 py-8">
                        <CardDashboardAnalytics
                          statSubtitle={`Revenue`}
                          statTitle={`${revenue}`}
                          statIconName="fas fa-dollar-sign"
                          statIconColor="bg-emerald-400"
                          statDescripiron="Vendor's Commissions"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <hr />
                <h3 className="font-medium mr-2 mb-4 px-4 mt-8">
                  Business Hours
                </h3>
                <div className="flex flex-wrap items-center text-sm md:text-base mb-12 w-full overflow-x-auto">
                  <table className="items-center w-full bg-transparent border-collapse table-auto ">
                    <thead>
                      <tr>
                        {thead.map((head, i) => {
                          return (
                            <th
                              key={i}
                              className={
                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left color-light bg-slate-50 text-slate-500 border-slate-100"
                              }
                            >
                              {head}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {selectedVendor?.availabilities?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {item.dayOfWeek.fullName}
                            </td>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center">
                              {getFormatedHour12(item.startTime)}
                            </td>
                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              to
                            </td>

                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                              {getFormatedHour12(item.endTime)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* <hr /> */}
            {activeTab === 2 && (
              <div className="bg-white rounded-lg">
                <div className="w-full p-8 ">
                  <h3 className="text-slate-700 text-2xl font-bold">
                    Services
                  </h3>
                </div>
                <div className="w-full p-4 flex flex-wrap justify-start ">
                  {selectedVendor?.services?.map((service) => {
                    return (
                      <div
                        className="service-card w-1/3 m-4 shadow-lg"
                        key={service.id}
                      >
                        <Service service={service} />
                      </div>
                    );
                  })}
                  {selectedVendor?.services?.length == 0 && (
                    <img
                      className="mx-auto"
                      src={
                        require("../../../assets/svg/no_data_image_placeholder.svg")
                          .default
                      }
                      alt="no_data_image_placeholder"
                    />
                  )}
                </div>
              </div>
            )}
            {activeTab === 3 && (
              <div className="bg-white rounded-lg">
                <div className="w-full p-8 ">
                  <h3 className="text-slate-700 text-2xl font-bold">
                    Products
                  </h3>
                  <Products />
                </div>
                <div className="w-full p-4 flex flex-wrap justify-start ">
                  {/* {selectedVendor?.services?.map((service) => {
                    return (
                      <div
                        className="service-card w-1/3 m-4 shadow-lg"
                        key={service.id}
                      >
                        <Service service={service} />
                      </div>
                    );
                  })} */}
                  {/* <Products /> */}
                  {selectedVendor?.products?.length == 0 && (
                    <img
                      className="mx-auto"
                      src={
                        require("../../../assets/svg/no_data_image_placeholder.svg")
                          .default
                      }
                      alt="no_data_image_placeholder"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
