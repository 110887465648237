import React from "react";
import PropTypes from "prop-types";
// components
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import moment from "moment";
import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import photo from "../../assets/img/team-4-470x470.png";
import { BlockUser, UnblockUser } from "../../store/actionCreators/users";

export default function CardTable(props) {
  const dispatch = useDispatch();

  const resetSearch = () => {
    window.location.reload();
  };

  const goToUser = (id) => {
    window.location.replace(`/admin/brokers/${id}`);
  };

  const handleBlock = (e, user) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (user.isBlocked) {
          dispatch(UnblockUser(user.id))
          .then((res) => {              
              Swal.fire(`User unblocked!`, "", "success");
              window.location.reload();
            })
            .catch((error) => {
              console.log("error", error.response);
            });
        } else {
          dispatch(BlockUser(user.id))
          .then((res) => {              
              Swal.fire(`User blocked!`, "", "success");
              window.location.reload();
            })
            .catch((error) => {
              console.log("error", error.response);
            });
        }
      }
    });
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t py-3 border-0 flex justify-between items-center  mb-3 m-4 ">
          <h6 className="text-slate-700 text-2xl font-bold">{props.title}</h6>
          <button
            onClick={resetSearch}
            className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          >
            Reset Search
          </button>
        </div>
        <div className="w-full 2xl:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                Search by first name, last name or email
              </label>
              <input
                placeholder="First name, last name, email"
                required
                maxLength="50"
                type="text"
                onChange={(e) => props.setSearchValue(event.target.value)}
                value={props.searchValue}
                className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                onKeyDown={props.handleKeyDown}
              />
            </div>
          </div>
        <div className="block w-full overflow-x-auto justify-between">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {props.thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                    >
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.firstName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.lastName}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.email}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.phoneNumber}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.joinedDate
                          ? moment(user.joinedDate).format("LL")
                          : "Never"}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.blockedDate
                          ? moment(user.blockedDate).format("LL")
                          : "Not Blocked"}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                        <button
                          onClick={(e) => handleBlock(e, user)}
                          title={user?.blockedDate && `Blocked on ${moment(user.blockedDate).format("LL")}`}
                          className={`
                            ${user.isBlocked 
                              ? "bg-red-400 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
                              : "success-green" +
                                " text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"}`
                          }
                          type="button"
                        >
                          {user.isBlocked ? "Unblock" : "Block"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
