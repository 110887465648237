let ApiURL = "";

if (process.env.MODE === "development") {
  ApiURL = "https://api.staging.bookit.vogdevelopment.com/api";
}
if (process.env.MODE === "uat") {
  ApiURL = "https://api.uat.bookit.vogdevelopment.com/api";
}
if (process.env.MODE === "production") {
  ApiURL = "https://api.thebookitapp.com/api";
}

export const baseURL = ApiURL;
