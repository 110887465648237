import React, { useEffect, useState } from "react";

// components

import UsersTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetVendors,
  GetSalesPersonVendors,
  SearchVendors,
} from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState();

  const thead = [
    "Logo",
    "Business Name",
    "Email",
    "Approval Status",
    "Joined On",
    "Action",
  ];

  const state = useSelector((state) => state.users.vendors);
  const user = useSelector((state) => state.account.userInfo);

  const dispatch = useDispatch();

  let isAdmin =
    user?.roles?.includes("administrator") ||
    user?.roles?.includes("superadministrator");

  useEffect(() => {
    if (!isAdmin) {
      if (searchValue) {
        const timeoutId = setTimeout(() => {
          // dispatch(SearchVendors(encodeURIComponent(searchValue), page, limit));
          Swal.fire("Search sales person's vendors.", "", "warning");
        }, 1000);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetSalesPersonVendors(searchValue, page, limit));
      }
    } else {
      if (searchValue) {
        const timeoutId = setTimeout(() => {
          dispatch(SearchVendors(encodeURIComponent(searchValue), page, limit));
        }, 1000);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetVendors(searchValue, page, limit));
      }
    }
  }, [page, limit, user, searchValue, isAdmin]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetVendors(searchValue, page, limit));
    }
  };

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UsersTable
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            handleKeyDown={handleKeyDown}
            title="Vendors"
            thead={thead}
            data={state && state.items}
            isAdmin={isAdmin}
          />
          <Pagination
            page={page}
            onPage={state && state.currentPage}
            setPage={setPage}
            totalPages={state && state.totalPages}
            searchValue={searchValue}
          />
        </div>
      </div>
    </>
  );
}
