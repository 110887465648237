import React, { useEffect, useState } from "react";

// components

import UsersTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import Pagination from "../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCustomers,
  SearchCustomers,
  SearchSalesAdminCustomers,
  GetSalesadminCustomers,
} from ".././../store/actionCreators/users";
import { Link } from "react-router-dom";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");

  const thead = [
    "Firstname",
    "Lastname",
    "Email",
    "Phone",
    "Joined On",
    // "Is Blocked",
    "Blocked on",
    "Action",
  ];

  const state = useSelector((state) => state.users.customers);
  const user = useSelector((state) => state.account.userInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.roles?.includes("sales_officer")) {
      if (searchValue) {
        const timeoutId = setTimeout(() => {
          dispatch(
            SearchSalesAdminCustomers(
              encodeURIComponent(searchValue),
              page,
              limit
            )
          );
        }, 500);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetSalesadminCustomers(page, limit));
      }
    } else if (user?.roles?.includes("superadministrator")) {
      if (searchValue) {
        const timeoutId = setTimeout(() => {
          dispatch(
            SearchCustomers(encodeURIComponent(searchValue), page, limit)
          );
        }, 500);
        return () => {
          clearTimeout(timeoutId);
        };
      } else {
        dispatch(GetCustomers(searchValue, page, limit));
      }
    }
  }, [page, limit, user, searchValue]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && user?.roles?.includes("sales_officer")) {
      dispatch(
        SearchSalesAdminCustomers(encodeURIComponent(searchValue), page, limit)
      );
    } else if (
      e.key === "Enter" &&
      user?.roles?.includes("superadministrator")
    ) {
      dispatch(SearchCustomers(encodeURIComponent(searchValue), page, limit));
    }
  };

  return (
    <>
      <AdminNavbar />

      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <UsersTable
            setSearchValue={setSearchValue}
            handleKeyDown={handleKeyDown}
            title="Customers"
            thead={thead}
            data={state && state.items}
          />
          <Pagination
            page={page}
            onPage={state.currentPage}
            setPage={setPage}
            totalPages={state.totalPages}
            searchValue={searchValue}
          />
        </div>
      </div>
    </>
  );
}
