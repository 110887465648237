import React from "react";
import { useState, useEffect, useRef } from "react";
import StripeIcon from "../../assets/svg/STRIPE_LOGO.svg";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  ConnectStripeExpress,
  GoToStripeDashboard,
} from "../../store/actionCreators/paymentMethods";

export default function StripeConnect({ onConnect, className }) {
  const dispatch = useDispatch();
  const stripeId = useSelector(
    (store) => store.account.userInfo.stripeExpressAccountId
  );
  const userId = useSelector((store) => store.account.userInfo.id);  

  const onStripeConnect = () => {
    dispatch(ConnectStripeExpress(userId))
      .then((res) => {
        window.location.href = res;
      })
      .catch((error) => {
        console.log("error", error.response);
        Swal.fire("", error.data.message, "error");
      });
  };

  const onStripeDashboard = () => {
    dispatch(GoToStripeDashboard(userId))
      .then((res) => {   
        window.location.href = res;
      })
      .catch((error) => {
        console.log("error", error.response);
        Swal.fire("", error.data.message, "error");
      });
  };

  return (
    <>
      <div className={`container border rounded px-4 lg:px-10 py-10`}>
        <div className="flex">
          <div className="mr-4">
            <div className="flex justify-center w-full p-4 border rounded-xl">
              <img src={StripeIcon} style={{ maxWidth: "200px" }} />
            </div>
          </div>
          <div className="flex justify-center items-center align-middle">
            <div
              title={
                stripeId
                  ? "Go to Stripe Dashboard"
                  : "Connect to Stripe Express"
              }
              onClick={stripeId ? onStripeDashboard : onStripeConnect}
              className="cursor-pointer font-bold text-brandingPrimary "
            >
              {stripeId ? "Visit Dashboard" : "Connect"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
