import * as actionTypes from "../action-types";
// import Axios from "axios";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";

export const AddPaymentMethod = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.post(`/v1/stripe/paymentsource`, data);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const GetAllPaymentMethods = () => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        const res = await Axios.get(`/v1/stripe/paymentsource`);
        dispatch({
          type: actionTypes.FETCH_PAYMENT_METHODS_SUCCESS,
          payload: res.data,
        });
        resolve(res);
      } catch (error) {
        dispatch({ type: actionTypes.FETCH_PAYMENT_METHODS_FAILURE, error });
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
        console.log(error);
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
  };
};

export const PaymentMethodAdded = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  try {
    dispatch({
      type: actionTypes.PAYMENT_METHOD_ADDED,
      payload: data,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};

export const StripeAccountAdded = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  try {
    dispatch({
      type: actionTypes.STRIPE_ACCOUNT_ADDED,
      payload: data,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    console.log(error);
  }
};

export const ConnectStripeExpress = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.get(`/v1/Admin/${id}/stripe-connect`);
        resolve(res);
      } catch (error) {
        reject(error.response);
        console.log("Error", error.response);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
  };
};

export const GoToStripeDashboard = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.get(`/v1/Admin/${id}/stripe-dashboard`);
        resolve(res);
      } catch (error) {
        reject(error.response);
        console.log("Error", error.response);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
  };
};
