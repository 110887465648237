import * as actionTypes from "../action-types";

let initialState = {
  user: {},
  isLogin: false,
  userInfo: {},
  loading: false,
  error: null,
};
function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.VALIDATE:
      return {
        ...state,
        isLogin: action.payload,
      };
    case actionTypes.SET_USER:
      let list = action.payload;
      return {
        state,
        user: { ...list },
      };
    case actionTypes.FETCH_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    case actionTypes.FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
export default reducer;
