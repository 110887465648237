import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// components
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import photo from "../../assets/img/team-4-470x470.png";
import { CreateVendor } from "../../store/actionCreators/users";
import EditSalesPerson from "./EditSalesPerson/";
import * as actionTypes from "../../store/action-types";

export default function CardTable(props) {
  const [slide, setSlide] = useState("1");
  const [moreInfo, setMoreInfo] = useState({});
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const resetSearch = () => {
    window.location.reload();
  };

  const addSalesPerson = () => {
    window.location.replace(`/admin/sales-persons/add`);
  };

  const goToUser = (id) => {
    navigate(`/admin/sales-persons/edit/${id}`);
  };

  const gotToDetails = (id) => {
    setSlide("2");
    setMoreInfo(id);
    dispatch({ type: actionTypes.SHOW_PAGINATION, payload: false });
  };

  return (
    <>
      {slide === "1" && (
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full  max-w-full flex-grow flex-1 lg:flex items-center justify-between">
                <h6 className="text-slate-700 text-2xl font-bold">
                  {props.title}
                </h6>
                <div className="relative mb-3 m-4 ">
                  <div>
                    <button
                      onClick={addSalesPerson}
                      className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                      Invite
                    </button>
                    <button
                      onClick={resetSearch}
                      className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    >
                      Reset Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto justify-between">
            <div className="w-full 2xl:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Search by first name, last name or email
                </label>
                <input
                  placeholder="First name, last name, email"
                  required
                  maxLength="50"
                  type="text"
                  onChange={(e) => props.setSearchValue(event.target.value)}
                  value={props.searchValue}
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onKeyDown={props.handleKeyDown}
                />
              </div>
            </div>

            {/* Projects table */}
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  {props.thead.map((head, i) => {
                    return (
                      <th
                        key={i}
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (props.color === "light"
                            ? "bg-slate-50 text-slate-500 border-slate-100"
                            : "bg-sky-800 text-sky-300 border-sky-700")
                        }
                      >
                        {head}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {props.data &&
                  props.data.map((user, i) => {
                    return (
                      <tr key={i}>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          <span
                            className={
                              "ml-3 font-bold " +
                              +(props.color === "light"
                                ? "text-slate-600"
                                : "text-white")
                            }
                          >
                            {user?.firstName}
                          </span>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {user?.lastName}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {user?.email}
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {user?.phoneNumber}
                        </td>
                       
                        <td
                          onClick={() => gotToDetails(user)}
                          className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left "
                        >
                          <i className="far fa-edit cursor-pointer"></i>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {slide === "2" && (
        <EditSalesPerson
          setSlide={setSlide}
          slide={slide}
          moreInfo={moreInfo}
        />
      )}
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
