import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
// import IconCheck from "../../assets/svg/icon_check.svg";

import {
  postPassword,
  resetPassword,
} from "../../store/actionCreators/accounts";
import { getPasswordValidationRules } from "../../utils/common";
import {
  PasswordRule,
  PasswordStrengthLevel,
  checkPassword,
} from "../../utils/common";

export default function Login() {
  const [email, setEmail] = useState(localStorage.getItem("emailForReset"));
  const [emailError, setEmailError] = useState(null);
  const [oneTimePassword, setOneTimePassword] = useState(
    localStorage.getItem("tempPasswordReset")
  );
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  // const [password, setPassword] = useState(localStorage.getItem("tempPasswordReset"));
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [strength, setStrength] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [strengthColor, setStrengthColor] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");
  const [typePassword, setTypePassword] = useState("text");

  const passwordRules = getPasswordValidationRules();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.show);

  useEffect(() => {
    let parts = window.location.pathname.split("/");
    setCurrentUrl(parts[2]);
  }, []);

  useEffect(() => {
    // setTypePassword(true)
  }, [typePassword]);

  const getPasswordStrength = (validationResults) => {
    switch (validationResults.filter((item) => item.valid === true).length) {
      case 0:
        setStrength(PasswordStrengthLevel.Weak.description);
        setStrengthColor("text-coralRed");
        break;
      case 1:
        setStrength(PasswordStrengthLevel.Good.description);
        setStrengthColor("text-primary");
        break;
      case 2:
        setStrength(PasswordStrengthLevel.Strong.description);
        setStrengthColor("text-caribbeanGreen");
        break;
      default:
        setStrength("");
        setStrengthColor("text-regentGray");
    }
  };

  const buttonDisabled = () => {
    return (
      !passwordValid === true ||
      password !== passwordConfirmation ||
      password === "" ||
      loading
    );
  };

  const setNewPassword = (e) => {
    e.preventDefault();
    let obj;
    if (currentUrl === "reset-password") {
      obj = {
        email,
        code,
        password,
        passwordConfirmation,
      };
      console.log(obj);
      dispatch(resetPassword(obj));
    } else {
      obj = {
        email,
        oneTimePassword,
        password,
        passwordConfirmation,
      };
      dispatch(postPassword(obj));
    }
  };
  console.log("email", email);
  console.log("oneTimePassword", oneTimePassword);
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-auto ">
          <div className="w-full lg:w-6/12 px-6">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-32 shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-indigo-600 text-3xl font-bold">
                    Set your new password
                  </h6>

                  <h6 className="text-slate-500 text-xl mt-2">
                    Please enter a new password for your account
                  </h6>
                </div>

                <hr className="mt-6 border-b-1 border-slate-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={setNewPassword}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      required
                      type={"email"}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  {currentUrl === "reset-password" ? (
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Code
                      </label>
                      <input
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength="6"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Code received in email"
                        required
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                      />
                    </div>
                  ) : (
                    <div className="relative w-full mb-3 ">
                      <label
                        className="block uppercase text-slate-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Temporary Password
                      </label>
                      <input
                        type="password"
                        // type={typePassword}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password received in email"
                        required
                        value={oneTimePassword}
                        onChange={(e) => setOneTimePassword(e.target.value)}
                      />
                      
                    </div>
                  )}
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      required
                      onChange={(e) => {
                        let value = e.target.value;
                        setPassword(value);

                        if (value) {
                          let validationResults = Object.keys(
                            passwordRules
                          ).map((r) => {
                            return {
                              rule: r,
                              valid: passwordRules[r].test(value.toString()),
                            };
                          });
                          setPasswordValid(
                            validationResults.every((v) => v.valid)
                          );
                          getPasswordStrength(validationResults);
                        } else {
                          setPasswordValid(true);
                        }
                      }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-slate-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      required
                      onChange={(e) => {
                        setPasswordConfirmation(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mt-6">
                    <p className="text-sm text-regentGray">Password Rules:</p>
                    <ul className="ml-2 mt-2.5 flex flex-col text-xs font-normal text-regentGray">
                      <li className="flex items-center my-1">
                        <div>
                          {/* <IconCheck height="22" width="22" fill="white" /> */}
                        </div>
                        <p>
                          {" "}
                          {password == passwordConfirmation &&
                            passwordConfirmation &&
                            password && <i className="fas fa-check mr-2"></i>}
                          Both passwords must match
                        </p>
                      </li>
                      <li className="flex items-center my-1">
                        <div>
                          {/* <IconCheck height="22" width="22" fill="white" /> */}
                        </div>
                        <p>
                          {checkPassword(passwordConfirmation) && (
                            <i className="fas fa-check mr-2"></i>
                          )}
                          Minimum 8 letter password, with at least a symbol,
                          upper and lower case letters and a number.
                        </p>
                      </li>
                    </ul>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={buttonDisabled()}
                      style={
                        buttonDisabled()
                          ? { cursor: "not-allowed", backgroundColor: "grey" }
                          : { cursor: "pointer" }
                      }
                      className="bg-indigo-600 text-white active:bg-slate-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Set Password
                    </button>
                  </div>
                </form>
                <div className="flex flex-wrap mt-6 relative text-center">
                  <div className="">
                    <Link
                      to="/auth/forgot-password"
                      className="text-blueGray-200 font-bold px-4"
                    >
                      <small>Go back</small>
                    </Link>
                    |
                    <Link
                      to="/auth/login"
                      className="text-blueGray-200 font-bold px-4"
                    >
                      <small>Sign in</small>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
