import { combineReducers } from "redux";
import history from "../../history";
import users from "./usersReducer";
import feedback from "./ticketsReducer";
import analytics from "./analyticsReducer";
import quarterlyReports from "./quarterlyReportsReducer";
import settings from "./settings";
import account from "./account";
import loading from "./loading";
import paymentMethods from "./paymentMethods";
import categories from "./categories";
import products from "./productsReducer";

let reducers = {
  users,
  account,
  loading,
  feedback,
  settings,
  analytics,
  quarterlyReports,
  paymentMethods,
  categories,
  products,
};
let combinedReducer = combineReducers(reducers);
export default combinedReducer;
