import React from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { CreateVendor, GetVendor } from "../../store/actionCreators/users";

import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import photo from "../../assets/img/team-4-470x470.png";

export default function CardTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetSearch = () => {
    window.location.reload();
  };

  const goToUser = (id) => {
    navigate(`/admin/vendors/${id}`);
  };

  function statusDetails(s) {
    let text = "";
    let cls = "bg-gray text-grayDark";
    let iconCls = "";
    switch (s) {
      case "APPROVED":
        text = "Profile Approved";
        cls = "bg-emerald-400 text-white px-2";
        iconCls = "fas fa-check text-emerald-500";
        break;
      case "WAITING_FOR_APPROVAL":
        text = "Profile Changed";
        cls = "text-orange-500 px-2";
        iconCls = "fas fa-exclamation text-orange-500";
        break;
      case "DECLINED":
        text = "Declined";
        cls = "bg-red-100 text-red-500";
        iconCls = "fas fa-exclamation text-red-500";
        break;
    }
    return { text: text, cls: cls };
  }

  function ProfileStatus({ s }) {
    let detail = statusDetails(s);
    return (
      <span
        className={`rounded text-xs h-fit px-1 font-bold whitespace-nowrap ${detail.cls}`}
      >
        {detail.text}
      </span>
    );
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0 lg:flex justify-between items-center">
          <h6 className="text-slate-700 text-2xl font-bold">{props.title}</h6>
          <div className=" mb-3 m-4 ">
            <Link to="/admin/vendors/add">
              <button className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
                Add Vendor
              </button>
            </Link>
            {props.isAdmin && (
              <button
                onClick={resetSearch}
                className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              >
                Reset Search
              </button>
            )}
          </div>
        </div>

        <div className="block w-full overflow-x-auto justify-between">
          {props.isAdmin && (
            <div className="w-full 2xl:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Search by first name, last name or email
                </label>
                <input
                  placeholder="First name, last name, email"
                  required
                  maxLength="50"
                  type="text"
                  onChange={(e) => props.setSearchValue(event.target.value)}
                  value={props.searchValue}
                  className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onKeyDown={props.handleKeyDown}
                />
              </div>
            </div>
          )}

          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {props.thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                    >
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <img
                          src={user?.companyLogo?.url}
                          className="w-8 h-8 object-cover"
                        />
                      </td>
                      <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        {user.businessName}
                      </th>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.email}
                      </td>

                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap">
                        {/* {user?.approvalStatus === "WAITING_FOR_APPROVAL" && (
                          <i className="fas fa-exclamation text-orange-500" />
                        )} */}
                        <ProfileStatus s={user?.approvalStatus} />
                        <br />
                        {user?.serviceChanged && (
                          <p className="px-1 font-bold whitespace-nowrap text-orange-500 pt-2">
                            {/* <i className="fas fa-exclamation text-orange-500 mr-2 mt-2" /> */}
                            Service changed
                          </p>
                        )}
                        {user?.productChanged && (
                          <p className="px-1 font-bold whitespace-nowrap text-orange-500 pt-2">
                            {/* <i className="fas fa-exclamation text-orange-500 mr-2 mt-2" /> */}
                            Product changed
                          </p>
                        )}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {user.createdAt
                          ? moment(user.createdAt).format("LLL")
                          : "Never"}
                      </td>

                      <td
                        onClick={() => {
                          goToUser(user.userId);
                        }}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left "
                      >
                        <i className="far fa-edit cursor-pointer"></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
