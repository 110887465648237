import React, { useState, useRef, useEffect, memo } from "react";
import { useLocation, Link, Outlet, useNavigate } from "react-router-dom";

import StripeCardElement from "../../components/CardElement/index.js";
// import CheckboxField from "@/components/CheckboxField";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  GetAllPaymentMethods,
  PaymentMethodAdded,
} from "../../store/actionCreators/paymentMethods";

const AddPaymentDetails = (props) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    stripeTokenError: "",
    stripeToken: "",
    setToDefault: true,
  });

  const location = useLocation();
  const navigate = useNavigate();

  function onSubmit() {
    ref.current.handleSubmitNewCard();
  }

  const setSuccess = () => {
    dispatch(GetAllPaymentMethods());
    dispatch(PaymentMethodAdded(true));
    Swal.fire(`Card saved successfully!`, "", "success");
    navigate("/admin/payment-details");
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Add Payment Method
                </h6>
                <button
                  className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  onClick={() =>
                    navigate("/admin/payment-details")
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 ">
              <StripeCardElement
                formData={formData}
                setFormData={setFormData}
                ref={ref}
                // setDefault={defaultCard}
                onSuccess
                setSuccess={setSuccess}
              />
            </div>
            {/* <div className="relative flex items-center mt-3">
              <div className="flex w-full justify-between items-center h-5">
                <div className="text-sm">
                  <label
                    htmlFor="defaultCard"
                    className="font-medium text-gray-700 text-sm"
                  >
                    Set Default Card
                    <input
                      id={"default"}
                      aria-describedby="comments-description"
                      name="defaultCard"
                      type="checkbox"
                      className=" h-6 w-6 text-brandingPrimary border-gray-400 border-2 focus:ring-0 rounded-sm"
                      onChange={onChange}
                      checked={value}
                      defaultChecked={true}
                    />
                  </label>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentDetails;
