import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetCategories } from ".././../store/actionCreators/categories";

// components
import CategoriesTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import DetailModal from "./detailModal";
import Pagination from "../../components/Pagination";

export default function Ads() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("vendor");
  const [feedbackType, setFeedbackType] = useState("vendor");
  const [showModal, setShowModal] = React.useState(false);

  const user = useSelector((state) => state.account.userInfo);
  const all = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const thead = ["Name", "Active services", "Description", "Detail"];

  useEffect(() => {
    if (
      user?.roles?.includes("administrator") ||
      user?.roles?.includes("superadministrator")
    ) {
      dispatch(GetCategories(page, limit));
    } else {
      navigate("/admin/vendors");
    }
  }, [page, limit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetCategories(page, limit));
    }
  };

  const onPageChange = (value) => {
    setActiveTab(value);
    setFeedbackType(value);
    setPage(0);
  };

  return (
    <>
      <DetailModal showModal={showModal} setShowModal={setShowModal} />
      <AdminNavbar />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ">
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1 flex items-center justify-between">
                  <h6 className="text-slate-700 text-2xl font-bold">
                    Categories
                  </h6>
                  <button
                    className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    onClick={() => setShowModal(true)}
                  >
                    Add new category
                  </button>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto justify-between">
              <CategoriesTable
                setSearchValue={setSearchValue}
                handleKeyDown={handleKeyDown}
                thead={thead}
                data={all.items}
                page={page}
                limit={limit}
              />
              <Pagination
                page={page}
                onPage={all.currentPage}
                setPage={setPage}
                totalPages={all.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
