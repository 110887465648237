import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";
import { parseError } from "../../utils/common";

export const GetCategories =
  (page = 0, limit = 10) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_CATEGORIES_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Category/parentCategories?Page=${page}&PerPage=${limit}`
      );

      dispatch({
        type: actionTypes.FETCH_CATEGORIES_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_CATEGORIES_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetCategoryById = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_CATEGORY_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/v1/Category/${id}`);
    dispatch({
      type: actionTypes.FETCH_CATEGORY_SUCCESS,
      payload: res.data,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    dispatch({
      type: actionTypes.FETCH_CATEGORY_FAILURE,
      error,
    });
  }
};

export const ClearSelectedCategory = () => async (dispatch) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  try {
    dispatch({
      type: actionTypes.FETCH_CATEGORY_SUCCESS,
      payload: null,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    console.log(error);
  }
};

export const EditCategory = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
      try {
        let res = await Axios.put(`/v1/Category/${id}`, data);
        resolve(res);
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const CreateCategory = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
      try {
        let res = await Axios.post(`/v1/Category`, data);
        resolve(res);
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeleteCategory = (id, data) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.delete(`/v1/Category/${id}`, data);
        resolve(res);
      } catch (error) {
        console.log("Error", error);
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
  };
};
