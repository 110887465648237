import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components

import TicketsTable from "./datatable.js";
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCustomerFeedback,
  GetVendorFeedback,
} from ".././../store/actionCreators/feedback";

export default function Tables() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState("vendor");
  const [feedbackType, setFeedbackType] = useState("vendor");
  const thead = ["Category", "Submitted By", "Date", "Detail"];

  const state = useSelector((state) => state);
  const user = useSelector((state) => state.account.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user?.roles?.includes("administrator") ||
      user?.roles?.includes("superadministrator")
    ) {
      if (feedbackType === "vendor") {
        dispatch(GetVendorFeedback(searchValue, page, limit));
      } else {
        dispatch(GetCustomerFeedback(searchValue, page, limit));
      }
    } else navigate("/admin/vendors");
  }, [page, limit, feedbackType, user]);

  const tabItems = [
    { title: "Vendor Feedback", value: "vendor" },
    { title: "Customer Feedback", value: "customer" },
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(GetVendorFeedback(searchValue, page, limit));
    }
  };

  const onPageChange = (value) => {
    setActiveTab(value);
    setFeedbackType(value);
    setPage(0);
  };

  return (
    <>
      <AdminNavbar />
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ">
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h6 className="text-slate-700 text-2xl font-bold">
                    Feedback
                  </h6>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto justify-between">
              <div className="flex flex-wrap mb-6 mt-6">
                {tabItems &&
                  tabItems.map((item, index) => (
                    <div className="w-full lg:w-6/12 px-4" key={index}>
                      <div
                        onClick={() => onPageChange(item.value)}
                        className={`bg-gray-100 text-slate-600 active:bg-sky-600 font-bold w-full uppercase text-sm ml-2 px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 cursor-pointer text-center ${
                          item.value == activeTab && "bg-indigo-600 text-white"
                        }`}
                      >
                        {item.title}
                      </div>
                    </div>
                  ))}
              </div>
              <TicketsTable
                setSearchValue={setSearchValue}
                handleKeyDown={handleKeyDown}
                thead={thead}
                data={state.feedback && state.feedback.allTickets.items}
                page={page}
                onPage={state.feedback.allTickets.currentPage}
                setPage={setPage}
                totalPages={state.feedback.allTickets.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
