import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  GetAllPaymentMethods,
  //   GetPaymentMethodById,
  //   ClearSelectedPaymentMethod,
  //   GetDefaultPaymentMethod,
  //   DeletePaymentMethod,
  //   EditPaymentMethod,
} from "../../store/actionCreators/paymentMethods";
import StripeConnect from "./StripeConnect";
import Mastercard from "../../assets/svg/Logo_mastercard.svg";
import Visa from "../../assets/svg/Logo_Visa.svg";

export default function PaymentDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.loading.show);
  const allPaymentMethods = useSelector(
    (store) => store.paymentMethods.paymentMethods
  );

  const CardBrandLogo = (cardBrand) => {
    let brandLogo;

    switch (cardBrand) {
      case "visa":
        brandLogo = (
          <img
            // src={require("../../assets/svg/Logo_Visa.svg")}
            src={Visa}
            alt="logo"
            className="w-12 mr-4"
          />
        );
        break;
      case "mastercard":
        brandLogo = (
          <img
            // src={require("../../assets/svg/Logo_mastercard.svg")}
            src={Mastercard}
            alt="logo"
            className="w-12 mr-4 p-1"
          />
        );
        break;
      case "amex":
        brandLogo = (
          <img
            src={require("../../assets/img/Logo_Amex.png")}
            alt="logo"
            className="w-12 mr-4"
          />
        );
        break;
      default:
        break;
    }
    return brandLogo;
  };

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultMethod, setDefaultMethod] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cardBrand, setCardBrand] = useState();
  const [cardId, setCardId] = useState("");

  // useEffect(() => {
  //   dispatch(GetAllPaymentMethods());
  // }, []);

  // useEffect(() => {
  //   if (allPaymentMethods) {
  //     setPaymentMethods(allPaymentMethods);
  //   } else setPaymentMethods([]);

  // }, [allPaymentMethods]);

  const goToEditCard = (id) => {
    // navigate("/admin/payment-details/edit")
  };
  const goToAddCard = () => {
    navigate("/admin/payment-details/add");
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Stripe Express
                </h6>
                {/* <button
                  className="bg-sky-500 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  onClick={() => goToAddCard()}
                >
                  Add New Card
                </button> */}
              </div>
            </div>
            {/* <div className="flex-auto px-4 lg:px-10 py-10 ">
              <div className="flex justify-end"></div>
              {paymentMethods?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="flex justify-between border rounded-md mt-3 p-4 "
                  >
                    <div className="flex items-center">
                      <div className="flex items-center ">
                        {CardBrandLogo(item.cardBrand)}{" "}
                        <span className="uppercase mr-2">
                          {item.cardBrand}{" "}
                        </span>
                        {` **** **** ****  ${item.cardLast4} ${
                          item.isDefault ? "Default" : ""
                        }`}
                      </div>
                    </div>
                    <div
                      className="hover:cursor-pointer scale-90"
                      onClick={() => {
                        setShowModal(true), setCardId(item.id);
                      }}
                    >
                      <i className="far fa-edit cursor-pointer" />
                    </div>
                  </div>
                );
              })}
            </div> */}

            <StripeConnect />
          </div>
        </div>
      </div>
    </>
  );
}
