import * as actionTypes from "../action-types";

let initialState = {
  documentText: {},
  loading: false,
  error: null,
  ads: [],
};
const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        documentText: action.payload,
      };
    case actionTypes.FETCH_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.POST_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.POST_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.POST_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
      case actionTypes.FETCH_ADS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_ADS_SUCCESS:
      return {
        ...state,
        ads: action.payload,
      };
    case actionTypes.FETCH_ADS_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default settingsReducer;
