import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// components
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
  GetProductDetails,
  ApproveProduct,
  DeclineProduct,
  GetAllProducts,
  GetSalesPersonProducts,
  GetVendorProducts,
} from "../../.././../store/actionCreators/products";

import Swal from "sweetalert2";
import ProductDetails from "./ProductDetails";

export default function CardTable(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeProduct = useSelector((state) => state.products.selectedProduct);

  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [changes, setChanges] = useState();

  useEffect(() => {
    if (showModal) {
      dispatch(GetProductDetails(selectedProductId));
    }
  }, [showModal]);

  useEffect(() => {
    if (storeProduct) {
      setSelectedProduct(storeProduct);
      setChanges(storeProduct.changes);
    }
  }, [storeProduct]);

  const goToProduct = (id) => {
    setSelectedProductId(id);
    setShowModal(true);
  };

  const approveProduct = () => {
    dispatch(ApproveProduct(selectedProductId))
      .then((res) => {
        Swal.fire(`Approved!`, "", "success");
        dispatch(GetProductDetails(selectedProductId));
        setShowModal(false);
        dispatch(
          GetVendorProducts(
            props.vendorId,
            props.searchValue,
            props.page,
            props.limit
          )
        );
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const declineProduct = () => {
    dispatch(DeclineProduct(selectedProductId))
      .then((res) => {
        Swal.fire(`Declined!`, "", "success");
        setShowModal(false);
        dispatch(GetProductDetails(selectedProductId));
        if (props.isAdmin) {
          dispatch(GetAllProducts(props.searchValue, props.page, props.limit));
        }
        if (props.isSalesRep) {
          dispatch(
            GetSalesPersonProducts(props.searchValue, props.page, props.limit)
          );
        }
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  function statusDetails(s) {
    let text = "";
    let cls = "bg-gray text-grayDark";
    let iconCls = "";
    switch (s) {
      case "APPROVED":
        text = "Approved";
        cls = "bg-emerald-400 text-white px-2";
        iconCls = "fas fa-check text-emerald-500";
        break;
      case "WAITING_FOR_APPROVAL":
        text = "Waiting for approval";
        cls = "text-orange-500 px-2";
        iconCls = "fas fa-exclamation text-orange-500";
        break;
      case "DECLINED":
        text = "Declined";
        cls = "bg-red-100 text-red-500";
        iconCls = "fas fa-exclamation text-red-500";
        break;
    }
    return { text: text, cls: cls };
  }

  function productStatusDetails(s) {
    let text = "";
    let cls = "bg-gray text-grayDark";
    switch (s) {
      case "IN_STOCK":
        text = "IN STOCK";
        cls = "bg-emerald-400 text-white px-2 rounded";
        break;
      case "OUT_OF_STOCK":
        text = "OUT OF STOCK";
        cls = "bg-red-500 text-white px-2 rounded";
        break;
      case "unavailable":
        text = "unavailable";
        cls = "bg-orange-500 text-white px-2 rounded";
        break;
      case "temporarily-unavailable":
        text = "temporarily unavailable";
        cls = "bg-orange-500 text-white px-2 rounded";
        break;
    }
    return { text: text, cls: cls };
  }

  function ProfileStatus({ s }) {
    let detail = statusDetails(s);
    return (
      <span
        className={`rounded text-xs h-fit px-1 font-bold whitespace-nowrap ${detail.cls}`}
      >
        {detail.text}
      </span>
    );
  }

  function ProductStatus({ s }) {
    let detail = productStatusDetails(s);
    return (
      <span
        className={`rounded-sm text-xs h-fit uppercase p-1 font-bold whitespace-nowrap ml-2 ${detail.cls}`}
      >
        {detail.text}
      </span>
    );
  }
  // console.log("selectedProduct", selectedProduct);
  console.log("page", props.page);
  console.log("limit", props.limit);
  console.log("searchValue", props.searchValue);
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (props.color === "light" ? "bg-white" : "bg-sky-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto justify-between">
          {props.title && (
            <div className="rounded-t mb-0 px-4 py-3 border-0 lg:flex justify-between items-center">
              <h6 className="text-slate-700 text-2xl font-bold">
                {props.title}
              </h6>
              <div className=" mb-3 m-4 ">
                <button
                  onClick={props.resetSearch}
                  className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs ml-2 px-2 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                  Reset Search
                </button>
              </div>
            </div>
          )}
          <div className="w-full 2xl:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                Search product
              </label>
              <input
                placeholder="Type product name"
                required
                maxLength="50"
                type="text"
                onChange={(e) => props.setSearchValue(event.target.value)}
                value={props.searchValue}
                className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                onKeyDown={props.handleKeyDown}
              />
            </div>
          </div>

          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {props.thead.map((head, i) => {
                  return (
                    <th
                      key={i}
                      className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (props.color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                      }
                    >
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.map((product, i) => {
                  return (
                    <tr key={i}>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 flex items-center ">
                        <img
                          src={product?.productImage?.url}
                          className="w-8 h-8 object-cover mr-2 rounded-full "
                        />
                        {product.name}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {`${product.currency} ${product.price}`}
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {`${product.quantity}`}
                      </td>
                      <td className=" p-4">
                        <ProductStatus
                          s={product.quantity > 0 ? "IN_STOCK" : "OUT_OF_STOCK"}
                        />
                      </td>
                      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap">
                        {product?.approvalStatus === "WAITING_FOR_APPROVAL" && (
                          <i className="fas fa-exclamation text-orange-500" />
                        )}
                        <ProfileStatus s={product?.approvalStatus} />
                        <br />
                        {/* {product?.hasChanges && (
                          <span className="px-1 font-bold whitespace-nowrap text-orange-500 pt-2 rounded text-xs h-fit px-2">
                            
                            Changed
                          </span>
                        )} */}
                      </td>
                      <td
                        onClick={() => {
                          goToProduct(product.id);
                        }}
                        // onClick={() => {
                        //   setShowModal(true);
                        // }}
                        // onClick={goToProduct}
                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left "
                      >
                        <i className="far fa-edit cursor-pointer"></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {showModal ? (
        <ProductDetails
          showModal={showModal}
          setShowModal={setShowModal}
          // product={selectedProduct}
          statusDetails={statusDetails}
          ProfileStatus={ProfileStatus}
          approve={approveProduct}
          decline={declineProduct}
          // changes={changes}
        />
      ) : (
        ""
      )}
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
