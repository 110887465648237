import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";
import { parseError } from "../../utils/common";

export const GetDocument = (type) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_DOCUMENT_REQUEST });

  try {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    const res = await Axios.get(`/settings/${type}`);
    dispatch({
      type: actionTypes.FETCH_DOCUMENT_SUCCESS,
      payload: res.data,
    });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_DOCUMENT_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });

    console.log(error);
  }
};

export const UpdateDocument = (type, data) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.POST_DOCUMENT_REQUEST });

  try {
    const res = await Axios.put(`/settings/${type}`, data);
    dispatch({
      type: actionTypes.POST_DOCUMENT_SUCCESS,
    });
    Swal.fire("", "Document updated!", "success");
  } catch (error) {
    dispatch({ type: actionTypes.POST_DOCUMENT_FAILURE, error });
    console.log(error);
    // Swal.fire("", "Error!", "error");
    parseError(error);
  }
};

export const GetPendingAds = (page, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_ADS_REQUEST });

  try {
    const res = await Axios.get(
      `/v1/Admin/adverts/pending?Page=${page}&PerPage=${limit}`
    );

    dispatch({
      type: actionTypes.FETCH_ADS_SUCCESS,
      payload: res,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_ADS_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  }
};

export const ApproveAd = (data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/advert/approve`, data);
        resolve(res);
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeclineAd = (id, data) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Admin/${id}/decline`, data);
        resolve(res);
      } catch (error) {
        parseError(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};
