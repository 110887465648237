import axios from "axios";
import TokenService from "./token.service";
import { baseURL } from "../utils/config";
import Swal from "sweetalert2";

console.log(baseURL);

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (
      config.url.split("/")[1] === "reports" &&
      config.url.split("/")[3] === "download"
    ) {
      config.responseType = "blob";
    }
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/v1/admin/login" && err.response) {
      // Access Token was expired
      if (originalConfig.url === "/v1/account/refreshtoken") return;
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/v1/account/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          Swal.fire(
            "Session expired.",
            "Please login to continue!",
            "info"
          ).then(function () {
            window.location.replace("/auth/login");
          });
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
