import React, { useState, useEffect, forwardRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from "./card";
// import { stripeKey } from "process.env.stripeKey";

// const stripeKey = STRIPE_KEY
const stripeKey = "pk_test_51LXs2gA1cUyZ2s3ZVD3uHAjBtXt8Z7VwmadR9mPGhfn39apf7BH24RAi9WONE04nEEGqWl3VLZdflvDPZtT110pN00lwr6VDfY"

const stripePromise = loadStripe(stripeKey);

const StripeCardElement = forwardRef((props, ref) => {

  const [loaded, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <React.Fragment>
      {loaded && (
        <Elements stripe={stripePromise}>
          <StripeCard ref={ref} formData={props.formData} setFormData={props.setFormData} error={props.error} setError={props.setError} setDefault={props.setDefault} onSuccess={props.onSuccess} setSuccess={props.setSuccess} />
        </Elements>
      )}     
    </React.Fragment>
  );
});

export default StripeCardElement;
