import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// components
import ProductsTable from "./datatable.js";
import Pagination from "../../../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";

import { GetVendorProducts } from "../../.././../store/actionCreators/products";
import Swal from "sweetalert2";

export default function Products() {
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState();

  const thead = [
    "Product",
    "Price",
    "Quantity",
    "Status",
    "Approval Status",
    "Action",
  ];

  const products = useSelector((state) => state.products.products);
  const user = useSelector((state) => state.account.userInfo);
  const { id } = useParams();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(GetVendorProducts(id));
  // }, []);

  useEffect(() => {
    if (searchValue) {
      const timeoutId = setTimeout(() => {
        dispatch(
          GetVendorProducts(id, encodeURIComponent(searchValue), page, limit)
        );
      }, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      dispatch(GetVendorProducts(id, searchValue, page, limit));
    }
  }, [page, limit, user, searchValue]);
  console.log("user", user);
  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          <ProductsTable
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            // handleKeyDown={handleKeyDown}
            // title="Products"
            thead={thead}
            limit={limit}
            page={page}
            data={products && products.items}
            vendorId={id}
          />
          <Pagination
            page={page}
            onPage={products && products.currentPage}
            setPage={setPage}
            totalPages={products && products.totalPages}
            searchValue={searchValue}
          />
        </div>
      </div>
    </>
  );
}
