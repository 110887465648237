import * as actionTypes from "../action-types";

let initialState = {
  loading: false,
  error: null,
  products: [],
  selectedProduct: {},
};
const products = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
      };
    case actionTypes.FETCH_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case actionTypes.FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default products;
