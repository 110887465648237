import { lastEventId } from "@sentry/react";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  changePassword,
  EditVendorInfo,
  GetLoggedInUserInfo,
} from "../../store/actionCreators/accounts";
import { checkPassword } from "../../utils/common";
import Swal from "sweetalert2";

export default function Settings() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [countryCode, setCountryCode] = useState("+1");
  const [fullPhone, setFullPhone] = useState("");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading.show);
  const userInfo = useSelector((state) => state.account.userInfo);

  useEffect(() => {
    if (newPassword === newPasswordConfirmation) {
      setButtonDisabled(false);
    }
    if (newPassword !== newPasswordConfirmation) {
      setButtonDisabled(true);
    }
  }, [newPassword, newPasswordConfirmation]);

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName);
      setLastName(userInfo.lastName);
      setPhone(userInfo?.phoneNumber?.substr(2));
      // setCountryCode(userInfo.phone);
    }
  }, [userInfo]);

  const changePasswordRequest = (e) => {
    e.preventDefault();
    let obj;
    obj = {
      currentPassword,
      newPassword,
      newPasswordConfirmation,
    };

    dispatch(changePassword(obj));
  };

  useEffect(() => {
    setFullPhone(countryCode + phone);
  }, [countryCode, phone]);

  const EditVendor = (e) => {
    e.preventDefault();
    let phone = fullPhone;

    let data = {
      firstName,
      lastName,
      phone,
    };

    dispatch(EditVendorInfo(data))
      .then(() => {
        Swal.fire(`Profile information edited successfully`, "", "success");
        dispatch(GetLoggedInUserInfo());
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  function formatPhoneNumber(str) {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const btnDisabled = () => {
    return firstName == "" || lastName == "" || phone == "";
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Personal Information
                </h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={EditVendor}>
                <div className="flex flex-wrap mb-6 mt-6">
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        First name
                      </label>
                      <input
                        placeholder="First name"
                        required
                        maxLength="50"
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Last name
                      </label>
                      <input
                        placeholder="Last name"
                        required
                        maxLength="50"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap mb-6">
                  <div className="w-full  px-4">
                    <div className="relative w-full mb-3">
                      <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                        Phone
                      </label>
                      <div className="w-full flex">
                        <input
                          disabled
                          defaultValue={countryCode}
                          placeholder="Country code, eg: +1"
                          pattern="[+][0-9]{1,3}"
                          onChange={(e) => setCountryCode(e.target.value)}
                          type="text"
                          maxLength="4"
                          style={{ width: "33%" }}
                          className="border-0 mr-2 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        />
                        <input
                          required
                          placeholder="1234567899"
                          pattern="[0-9]{10}"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone || ""}
                          type="text"
                          maxLength="10"
                          style={{ width: "61%" }}
                          className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring  ease-linear transition-all duration-150 ml-auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs mt-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                  disabled={btnDisabled}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-6/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-100 border-0">
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-slate-700 text-2xl  font-bold">
                  Change Password
                </h6>
              </div>
            </div>
            <div className="flex-auto  lg:px-10 py-10 pt-0">
              <form onSubmit={changePasswordRequest} className="mt-6 px-4">
                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  Old Password
                </label>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setCurrentPassword(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Current password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>

                <label className="block uppercase text-slate-600 text-xs font-bold mb-2">
                  New Password
                </label>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 ">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Enter password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <input
                        onChange={(e) => {
                          setNewPasswordConfirmation(e.target.value);
                        }}
                        required
                        type="password"
                        placeholder="Confirm password"
                        className="border-0 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <p className="text-sm text-regentGray">Password Rules:</p>
                  <ul className="ml-2 mt-2.5 flex flex-col text-xs font-normal text-regentGray">
                    <li className="flex items-center my-1">
                      <div>
                        {/* <IconCheck height="22" width="22" fill="white" /> */}
                      </div>
                      <p>
                        {" "}
                        {newPassword == newPasswordConfirmation &&
                          newPasswordConfirmation &&
                          newPassword && <i className="fas fa-check mr-2"></i>}
                        Both passwords must match
                      </p>
                    </li>
                    <li className="flex items-center my-1">
                      <div>
                        {/* <IconCheck height="22" width="22" fill="white" /> */}
                      </div>
                      <p>
                        {checkPassword(newPasswordConfirmation) && (
                          <i className="fas fa-check mr-2"></i>
                        )}
                        Minimum 8 letter password, with at least a symbol, upper
                        and lower case letters and a number.
                      </p>
                    </li>
                  </ul>
                </div>
                <button
                  disabled={buttonDisabled}
                  style={
                    buttonDisabled
                      ? { cursor: "not-allowed", backgroundColor: "grey" }
                      : { cursor: "pointer" }
                  }
                  className="bg-orange-500 text-white active:bg-sky-600 font-bold uppercase text-xs mt-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
