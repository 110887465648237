/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

import NotificationDropdown from "../../components/Dropdowns/NotificationDropdown.js";
import BreezeLogo from "../../assets/img/wordmark-TM-purple.png";
import { useSelector, useDispatch } from "react-redux";

export default function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [linkDisable, setLinkDisable] = React.useState(false);
  const logOut = () => {
    AuthService.logout();
  };

  const role =
    sessionStorage.getItem("user") &&
    JSON.parse(sessionStorage.getItem("user")).profile.roles[0];

  const paymentAdded = useSelector(
    (state) => state.paymentMethods.paymentMethodAdded
  );

  const stripeAccountAdded = useSelector(
    (state) => state.paymentMethods.stripeAccountAdded
  );

  useEffect(() => {
    if (role === "sales_officer" && !stripeAccountAdded) {
      setLinkDisable(true);
    } else {
      setLinkDisable(false);
    }
  }, [stripeAccountAdded, stripeAccountAdded]);

  // useEffect(() => {
  //   if (role === "sales_officer" && !paymentAdded) {
  //     setLinkDisable(true);
  //   } else {
  //     setLinkDisable(false);
  //   }
  // }, [paymentAdded]);

  console.log("stripeAccountAdded", stripeAccountAdded);

  return (
    <>
      <nav
        style={{
          background: "rgb(2,0,36)",
          background:
            "linear-gradient(180deg, rgba(0,228,191,1) 0%, rgba(53,180,206,1) 19%, rgba(134,60,250,1) 79%)",
        }}
        className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-72 z-10 py-4 px-6"
      >
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-sky-500 m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <div className="md:block mx-auto md:pb-2 text-slate-600 inline-block whitespace-nowrap text-sm uppercase font-bold p-2">
            <img className="logoImage" src={BreezeLogo} />
          </div>
          {/* User */}

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    BookIt
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            {role === "administrator" && (
              <>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
                {/* Heading */}
                <h6 className="md:min-w-full text-white text-sm uppercase font-bold block pt-1 pb-4 no-underline">
                  Something
                </h6>
              </>
            )}
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              {role === "administrator" && (
                <li className={"items-center"}>
                  <div
                    onClick={() =>
                      linkDisable
                        ? navigate("/admin/payment-details")
                        : navigate("/admin/dashboard")
                    }
                    className={
                      "cursor-pointer text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.pathname === "/admin/dashboard"
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                  >
                    Dashboard{" "}
                  </div>
                </li>
              )}
              {role === "administrator" && (
                <li className="items-center">
                  <Link
                    className={
                      "text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf("/admin/feedback") !== -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                    to="/admin/feedback"
                  >
                    Feedback
                  </Link>
                </li>
              )}
              {role === "administrator" && (
                <li className="items-center">
                  <Link
                    className={
                      "text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf("/admin/categories") !== -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                    to="/admin/categories"
                  >
                    Categories
                  </Link>
                </li>
              )}
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-white text-sm uppercase font-bold block pt-1 pb-4 no-underline">
              Users
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {role === "administrator" && (
                <li className="items-center">
                  <Link
                    className={
                      "text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf("/admin/sales-persons") !==
                      -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                    to="/admin/sales-persons"
                  >
                    Sales Persons
                  </Link>
                </li>
              )}
              <li className="items-center">
                <Link
                  className={
                    "text-md tracking-wide p-2 font-bold block rounded-lg " +
                    (window.location.href.indexOf("/admin/vendors") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-white hover:text-slate-300")
                  }
                  to={linkDisable ? "/admin/payment-details" : "/admin/vendors"}
                >
                  Vendors
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-md tracking-wide p-2 font-bold block rounded-lg " +
                    (window.location.href.indexOf("/admin/customers") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-white hover:text-slate-300")
                  }
                  to={
                    linkDisable ? "/admin/payment-details" : "/admin/customers"
                  }
                >
                  Customers
                </Link>
              </li>
            </ul>

            {/* Divider */}
            {/* <hr className="my-4 md:min-w-full" /> */}
            {/* Heading */}
            {/* <h6 className="md:min-w-full text-white text-sm uppercase font-bold block pt-1 pb-4 no-underline">
              Products
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-md tracking-wide p-2 font-bold block rounded-lg " +
                    (window.location.href.indexOf("/admin/products") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-white hover:text-slate-300")
                  }
                  to="/admin/products"
                >
                  All Products
                </Link>
              </li>
            </ul> */}

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-white text-sm uppercase font-bold block pt-1 pb-4 no-underline">
              Settings
            </h6>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              {/* {role === "administrator" && ( */}
              <li className="items-center">
                <div
                  onClick={() => navigate("/admin/post-ads")}
                  className={
                    "cursor-pointer text-md tracking-wide p-2 font-bold block rounded-lg " +
                    (window.location.href.indexOf("/admin/post-ads") !== -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-white hover:text-slate-300")
                  }
                  to="/admin/post-ads"
                >
                  Advertisements
                </div>
              </li>
              {/* )} */}
              {role === "administrator" && (
                <li className="items-center">
                  <Link
                    className={
                      "text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf(
                        "/admin/terms-of-service"
                      ) !== -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                    to="/admin/terms-of-service"
                  >
                    Terms of Service
                  </Link>
                </li>
              )}

              {role === "administrator" && (
                <li className="items-center">
                  <Link
                    className={
                      "text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf("/admin/privacy-policy") !==
                      -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                    to="/admin/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                </li>
              )}

              <li className="items-center">
                <div
                  onClick={() => {
                    linkDisable
                      ? navigate("/admin/payment-details")
                      : navigate("/admin/change-password");
                  }}
                  className={
                    "cursor-pointer text-md tracking-wide p-2 font-bold block rounded-lg " +
                    (window.location.href.indexOf("/admin/change-password") !==
                    -1
                      ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                      : "text-white hover:text-slate-300")
                  }
                >
                  Account Settings
                </div>
              </li>
              {role === "sales_officer" && (
                <li className="items-center">
                  <div
                    onClick={() =>
                      linkDisable
                        ? navigate("/admin/payment-details")
                        : navigate("/admin/payment-details")
                    }
                    className={
                      "cursor-pointer text-md tracking-wide p-2 font-bold block rounded-lg " +
                      (window.location.href.indexOf(
                        "/admin/payment-details"
                      ) !== -1
                        ? "text-breeze-violet hover:text-breeze-violet bg-slate-100"
                        : "text-white hover:text-slate-300")
                    }
                  >
                    Stripe Express
                  </div>
                </li>
              )}
            </ul>

            {/* Heading */}
            <hr className="my-4 md:min-w-full" />

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  onClick={logOut}
                  className="text-white hover:text-slate-300 text-lg p-5 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-sign-out-alt text-white mr-2 text-lg"></i>{" "}
                  Log out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
