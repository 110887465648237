/*eslint-disable*/
import React from "react";
import {} from "react-router-dom";
import BreezeLogo from "../../assets/img/wordmark-TM-purple-1.png";
// components

import PagesDropdown from "../../components/Dropdowns/PagesDropdown.js";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start mt-8">
            {/* <BitvoLogo fillColor="#fff" /> */}
            <img width="250px" src={BreezeLogo} />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}>
              <i className="text-white fas fa-bars"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
