import * as actionTypes from "../action-types";
import Axios from "../../services/api.js";
import Swal from "sweetalert2";
import { parseError } from "../../utils/common";

export const GetAllProducts =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Products/admin?Page=${page}&PerPage=${limit}&searchBy=${query}`
      );

      dispatch({
        type: actionTypes.FETCH_ALL_PRODUCTS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetVendorProducts =
  (id, query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Products/admin/${id}?Page=${page}&PerPage=${limit}&searchBy=${query}`
      );

      dispatch({
        type: actionTypes.FETCH_ALL_PRODUCTS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetSalesPersonProducts =
  (query, page, limit) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
    dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_REQUEST });

    try {
      const res = await Axios.get(
        `/v1/Products/salesadmin?Page=${page}&PerPage=${limit}&searchBy=${query}`
      );

      dispatch({
        type: actionTypes.FETCH_ALL_PRODUCTS_SUCCESS,
        payload: res,
      });
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ALL_PRODUCTS_FAILURE, error });
      dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
    }
  };

export const GetProductDetails = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
  dispatch({ type: actionTypes.FETCH_PRODUCT_REQUEST });

  try {
    const res = await Axios.get(`/v1/Products/admin/${id}/detail`);

    dispatch({
      type: actionTypes.FETCH_PRODUCT_SUCCESS,
      payload: res,
    });
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_PRODUCT_FAILURE, error });
    dispatch({ type: actionTypes.SHOW_LOADING, payload: false });
  }
};

export const ApproveProduct = (id) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Products/approve/${id}`);
        resolve(res);
      } catch (error) {
        parseError(error);
        console.log(error);
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};

export const DeclineProduct = (id) => {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
        let res = await Axios.put(`/v1/Products/decline/${id}`);
        resolve(res);
      } catch (error) {
        let errMessage = error.response.data.message;
        if (error.response.data.message) {
          Swal.fire("", errMessage, "error");
        }
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
      }
    });
};
