import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// components
import DetailModal from "./detailModal";
import Pagination from "../../components/Pagination";

export default function AdsTable(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [ad, setAd] = React.useState();

  const seeDetails = (data) => {
    // e.preventDefault();
    setAd(data);
    setShowModal(true);
  };

  return (
    <>
      <DetailModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedAd={ad}
        setSelectedAd={setAd}
      />

      {/* Projects table */}
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            {props.thead.map((head, i) => {
              return (
                <th
                  key={i}
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (props.color === "light"
                      ? "bg-slate-50 text-slate-500 border-slate-100"
                      : "bg-sky-800 text-sky-300 border-sky-700")
                  }
                >
                  {head}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((ad, i) => {
              return (
                <tr key={ad.advertId}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                    <span
                      className={
                        "ml-3 font-bold " +
                        +(props.color === "light"
                          ? "text-slate-600"
                          : "text-white")
                      }
                    >
                      {ad?.name}
                    </span>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {ad?.email}
                  </td>
                  <td
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 overflow-hidden"
                    style={{ maxWidth: 300 }}
                  >
                    {ad?.comment}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {moment.utc(ad.submittedAt).local().format("LLL")}
                  </td>

                  <td
                    onClick={() => seeDetails(ad)}
                    // onClick={() => setAd(ad)}
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer font-bold"
                  >
                    View
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Pagination
        page={props.page}
        onPage={props.onPage}
        setPage={props.setPage}
        totalPages={props.totalPages}
      />
    </>
  );
}

AdsTable.defaultProps = {
  color: "light",
};

AdsTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
