import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
// import { parseError } from "../../helpers/common";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { AddPaymentMethod } from "../../store/actionCreators/paymentMethods";

const StripeCard = forwardRef((props, ref) => {
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [loaded, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [expError, setExpError] = useState(null);

  // useImperativeHandle(ref, () => ({
  //   handleSubmitNewCard,
  // }));

  const dispatch = useDispatch();

  const elementStyles = {
    base: {
      color: "#4952DE",
      fontFamily: '"Roboto", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#BEBEBE",
      },
      backgroundColor: "#fff",
    },
    invalid: {
      color: "#b24a4a",
      iconColor: "#b24a4a",
    },
    focus: {
      boxShadow: "#707070",
      color: "blue",
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setLoad(true);
  }, []);

  useEffect(() => {
    if (!cardNumberValid || !cardCvcValid || !cardExpiryValid) {
      props.setFormData({ ...props.formData, stripeTokenError: true });
    } else {
      props.setFormData({ ...props.formData, stripeTokenError: false });
    }
  }, [cardNumberValid, cardCvcValid, cardExpiryValid, loading]);

  const handleCardNumberChange = (ev) => {
    ev.error || !ev.complete || ev.empty
      ? setCardNumberValid(false)
      : setCardNumberValid(true);
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError(null);
  };

  const handleCardExpiryChange = (ev) => {
    ev.error || !ev.complete || ev.empty
      ? setCardExpiryValid(false)
      : setCardExpiryValid(true);
    ev.error ? setExpError(ev.error.message) : setExpError(null);
  };

  const handleCardCvcChange = (ev) => {
    ev.error || !ev.complete || ev.empty
      ? setCardCvcValid(false)
      : setCardCvcValid(true);
    ev.error ? setCvcError(ev.error.message) : setCvcError(null);
  };

  const handleSubmitNewCard = async (callback) => {
    setLoading(true);
    setError(null);

    if (
      !stripe ||
      !elements ||
      !cardNumberValid ||
      !cardCvcValid ||
      !cardExpiryValid
    ) {
      setLoading(false);
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);

    const stripeToken = await stripe.createToken(cardNumber);

    if (!stripeToken.error) {
      let defCard;

      if (props.setDefault) {
        defCard = props.setDefault;
      } else {
        defCard = true;
      }

      const data = {
        stripeToken: stripeToken.token.id,
        setToDefault: defCard,
      };

      // console.log("setDefault propps", props.setDefault )
      // console.log("CARD DATA", data )

      dispatch(AddPaymentMethod(data))
        .then((res) => {
          props.setFormData({
            ...props.formData,
            stripeToken: stripeToken.token.id,
            setToDefault: defCard,
          });
          if (props.onSuccess) {
            props.setSuccess();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });

      // showSuccess();
    } else {
      setLoading(false);
      setError(stripeToken.error.message);
      Swal.fire(stripeToken.error.message);
    }
  };

  const showSuccess = (message) => {
    Swal.fire("Card added");
    // props.toggle();
  };

  return (
    <React.Fragment>
      {loaded && (
        <div className="">
          <p className="text-xs mb-2">Card Number</p>
          <div className="block p-2 w-full text-sm border appearance-none focus:ring-indigo-500 focus:border-[indigo-500] text-gray-900 border-[#DDDDDD] rounded-md bg-white mb-2 avvy-card">
            <CardNumberElement
              options={{
                style: elementStyles,
              }}
              onChange={handleCardNumberChange}
            />
          </div>
          {checkoutError && (
            <p className="text-[#b24a4a]  text-xs mb-4">{checkoutError}</p>
          )}
          <div className="flex gap-16 mt-2">
            <div style={{ width: "50%" }}>
              <p className="text-xs mb-2">Expiry Date</p>
              <div className="block p-2 w-full text-sm border appearance-none focus:ring-indigo-500 focus:border-[indigo-500] text-gray-900 border-[#DDDDDD] rounded-md bg-white mb-4 avvy-card">
                <CardExpiryElement
                  options={{
                    style: elementStyles,
                  }}
                  onChange={handleCardExpiryChange}
                />
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <p className="text-xs mb-2">CVC</p>
              <div className="block p-2 w-full text-sm border appearance-none focus:ring-indigo-500 focus:border-[indigo-500] text-gray-900 border-[#DDDDDD] rounded-md bg-white mb-4 avvy-card">
                <CardCvcElement
                  options={{
                    style: elementStyles,
                  }}
                  onChange={handleCardCvcChange}
                />
              </div>
            </div>
          </div>
          {expError && (
            <p className="text-[#b24a4a]  text-xs mb-4">{expError}</p>
          )}
          {cvcError && (
            <p className="text-[#b24a4a] text-xs mb-4">{cvcError}</p>
          )}
          <button
            disabled={
              !cardNumberValid || !cardExpiryValid || !cardCvcValid || loading
            }
            className="bg-indigo-500 mt-4 text-white active:bg-sky-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 cursor-pointer"
            onClick={() => handleSubmitNewCard()}
          >
            Submit card
          </button>
          {error && (
            <div
              className="error-alert"
              style={{
                margin: "12px auto",
                padding: "0.6rem",
                maxWidth: "500px",
              }}
            >
              {error}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
});

export default StripeCard;
